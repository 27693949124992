import cn from 'classnames';
import { ReactNode } from 'react';
import { TextBadgeProps } from '../ui/badge/ITextBadge';
import Dropdown from '../ui/dropdown/Dropdown';
import Link from '../ui/link/Link';

export type NavigationTabProps = {
  text: string;
  value: string;
  to?: string;
  href?: string;
  target?: string;
  primaryBadge?: TextBadgeProps | ReactNode;
  secondaryBadge?: TextBadgeProps | ReactNode;
  isActive?: boolean;
  disabled?: boolean;
};

export const NavigationTab = ({ text: label, to, href, target, secondaryBadge, isActive, disabled }: NavigationTabProps) => (
  <Link
    to={to}
    href={href}
    target={target}
    className={cn('no-underline border-b-4 border-white', {
      bold: isActive,
      'border-b-4': isActive,
      'border-primary': isActive,
      'hover:border-brand-10': !isActive && !disabled,
      'text-disabledText': disabled,
      'pointer-events-none': disabled,
    })}
    disabled={disabled}
    aria-label={label}
    role='menuitem'
    {...(isActive && { 'aria-current': 'page' })}
  >
    <div className='px-6 pt-2 pb-4 text-xl flex items-center'>
      {label}
      {secondaryBadge}
    </div>
  </Link>
);

export const SubpageNavigation = ({ tabs }: { tabs: NavigationTabProps[] }) => {
  const activeTab = tabs.find((tab: NavigationTabProps) => tab.isActive);
  const items = tabs.map(({ primaryBadge, secondaryBadge, isActive, ...tab }: NavigationTabProps) => ({
    ...tab,
    badge: primaryBadge,
  }));
  return (
    <>
      <div className='flex-row border-b border-gray-25 w-full hidden lg:flex' aria-label='Subpage navigation' role='menu'>
        {tabs?.map((tab: NavigationTabProps, index: number) => {
          return <NavigationTab {...tab} key={index} />;
        })}
      </div>
      <div className='w-full lg:hidden'>
        <Dropdown
          id='subpage-nav'
          text={activeTab?.text || 'Menu'}
          textBadge={activeTab?.primaryBadge}
          items={items}
          mobile={true}
          height='3.5rem'
        />
      </div>
    </>
  );
};
