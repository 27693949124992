import { sendEvent } from '../../../features/analytics/sendEvent';
import type { Loan, LoanOfficer, LoanTeamMember, Realtor } from '../../../features/loans/types';
import { content } from '../../../features/tenant/tenant';
import Avatar, { defaultMarginTop } from '../../ui/avatar/Avatar';
import { EmailButton } from '../../ui/button/EmailButton';
import { PhoneButton } from '../../ui/button/PhoneButton';
import { PhoneButtonGroup } from '../../ui/button/PhoneButtonGroup';
import SupportHours from './SupportHours';
import { isFundedLoan, isTasklistLoan } from '../../../features/loans/helpers';
import { useAppSelector } from '../../../hooks/hooks';
import { selectServicing, selectServicingMeta } from '../../../features/servicing/servicingSlice';
import { useRouteMatch } from 'react-router-dom';
import { PATHS } from '../../routes/paths';
import { Skeleton } from '../../ui/skeleton/Skeleton';
import cn from 'classnames';
import { useFetchServicing } from '../../../features/servicing/useFetchServicing';
import './Support.scss';
import useEmbed from '../../../hooks/useEmbed';
import ApplyLoanButton from '../../ui/button/ApplyLoanButton';
import { log } from '../../../utils/logger';
import { useLoanRealtor } from '../../../features/loans/hooks';

type SupportWidgetName =
  | 'loSupport'
  | 'mcSupport'
  | 'realtorSupport'
  | 'generalSupport'
  | 'retentionSupport'
  | 'servicingSupport'
  | 'personalLoanSupport'
  | 'helocFigureSupport'
  | 'helocUnknownServicerSupport'
  | 'helocServicingSupport';

export const SupportHeadline = ({ headline }: { headline: string }) => {
  return (
    <div className='title-text support-blurb mb-6'>
      <p className='title-text-text header-small bold'>{headline}</p>
    </div>
  );
};

const SupportHeadlineRow = ({
  className,
  headline,
  subHeadline,
}: {
  className?: string;
  headline: string;
  subHeadline?: string;
}) => {
  return (
    <div className={cn('flex flex-col', className)}>
      <p className='header-small bold'>{headline}</p>
      {subHeadline && <p className='text-md'>{subHeadline}</p>}
    </div>
  );
};

export const EmailPhoneWidget = ({
  className,
  widgetName,
  email,
  phone,
  phoneSuffix,
  phoneList,
  isLoading,
}: {
  className?: string;
  widgetName: SupportWidgetName;
  email?: string;
  phone?: string;
  phoneSuffix?: string;
  phoneList?: { phone: string; suffix?: string }[];
  isLoading?: boolean;
}) => {
  const handleEmailEvent = () => sendEvent(`${widgetName} EmailClick`, { email });
  const handlePhoneEvent = () => sendEvent(`${widgetName} PhoneClick`, { phone });
  const handlePhoneGroupEvent = (_event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>, phone: string) =>
    sendEvent(`${widgetName} PhoneClick`, { phone });

  if (isLoading) {
    return (
      <div className={cn('flex flex-col', className)}>
        <Skeleton className='w-48 mb-2' />
        <Skeleton className='w-48' />
      </div>
    );
  }

  return (
    <>
      {email || phone ? (
        <div className={cn('flex flex-col', className)}>
          {email && (
            <EmailButton
              buttonType='quaternary'
              size='small'
              showIcon={true}
              email={email}
              className='OneLinkNoTx leading-5'
              data-qa='person-email'
              onClick={handleEmailEvent}
            />
          )}
          {email && (phone || phoneList) && <div className='h-1' />}
          {(phone || phoneList) && (
            <>
              {phone && (
                <PhoneButton
                  buttonType='quaternary'
                  size='small'
                  showIcon={true}
                  phone={phone}
                  suffix={phoneSuffix}
                  className='leading-5'
                  data-qa='person-phone'
                  onClick={handlePhoneEvent}
                />
              )}
              {phoneList && (
                <PhoneButtonGroup
                  buttonType='quaternary'
                  size='small'
                  showIcon={true}
                  phoneList={phoneList}
                  className='leading-5'
                  data-qa='person-phone'
                  onPhoneClick={handlePhoneGroupEvent}
                />
              )}
            </>
          )}
        </div>
      ) : null}
    </>
  );
};

const LoanOfficerComponent = ({ loanOfficer }: { loanOfficer: LoanOfficer }) => {
  const { 'role-name': roleName, 'nmls-id': nmlsId, 'photo-url': photo, name, phone, email, title } = loanOfficer;
  return (
    <div className='flex flex-col sm:flex-row lg:flex-col'>
      <div className='flex flex-col sm:flex-row lg:flex-col'>
        <div className='flex mr-0 sm:mr-8 lg:mr-0'>
          <div>
            <Avatar
              className='mr-6'
              photo={photo}
              alt={`Photo of ${name}`}
              data-qa='person-photo'
              style={{ marginTop: defaultMarginTop }}
            />
          </div>
          <div className='h-full'>
            <p className='text-sm font-bold mb-1.5 OneLinkNoTx'>{name}</p>
            <p className='text-sm capitalize mb-1.5'>{title || roleName}</p>
            <p className='text-sm'>NMLS {nmlsId}</p>
          </div>
        </div>
      </div>
      <div className='mt-4 sm:mt-0 lg:mt-4'>
        <EmailPhoneWidget widgetName='loSupport' email={email} phone={phone} />
      </div>
    </div>
  );
};

export const LoadingContactComponentRow = () => (
  <div className='col-span-1 lg:col-span-2'>
    <div className='flex flex-row  md:w-full w-1/3'>
      <div className='flex flex-col'>
        <Skeleton className='mr-6 rounded-full' style={{ width: '72px', height: '72px' }} />
      </div>
      <div className='flex flex-col w-full mt-2 mr-2'>
        <Skeleton className='mb-2' />
        <Skeleton className='' />
      </div>
      <div className='flex flex-col w-full mt-2 mx-2 hidden md:inline'>
        <Skeleton className='mb-2' />
        <Skeleton />
      </div>
    </div>
    <div className='flex flex-col w-1/3 mt-4 md:hidden'>
      <Skeleton className='mb-2 mr-2' />
      <Skeleton className='mr-2' />
    </div>
  </div>
);

const LoadingContactComponent = () => {
  const lineStyle = { width: '100px', height: '20px' };
  return (
    <div className='flex flex-col sm:flex-row lg:flex-col'>
      <div className='flex flex-col sm:flex-row lg:flex-col'>
        <div className='flex mr-0 sm:mr-8 lg:mr-0'>
          <div>
            <Skeleton className='mr-6 rounded-full' style={{ width: '72px', height: '72px' }} />
          </div>
          <div className='h-full'>
            <Skeleton style={lineStyle} className='' />
            <Skeleton style={lineStyle} className='mt-1' />
            <Skeleton style={lineStyle} className='mt-1' />
          </div>
        </div>
      </div>
      <div className='mt-4 sm:mt-0 lg:mt-4 w-1/3 lg:w-full'>
        <Skeleton style={lineStyle} />
        <Skeleton style={lineStyle} className='mt-1' />
      </div>
    </div>
  );
};

const AvatarNameContactComponentRow = ({
  className,
  photo,
  name,
  desc1,
  desc2,
  email,
  phone,
  loId,
  empId,
  widgetName,
}: {
  className?: string;
  photo?: string;
  name: string;
  desc1: string;
  desc2?: string;
  email?: string;
  phone?: string;
  loId?: string;
  empId?: number;
  widgetName: SupportWidgetName;
}) => {
  const onStartApplicationClick = () => {
    sendEvent('startAnApplication: loanOfficerCard');
    log({ level: 'info', message: `startAnApplication: loanOfficerCard lo: ${JSON.stringify({ name, email, id: loId, empId })}` });
  };

  return (
    <div className={cn('grid grid-cols-1 md:grid-cols-9 gap-y-3 md:gap-0', className)}>
      <div className='col-span-1 md:col-span-5 flex flex-row'>
        <div>
          <Avatar className='lg:-mt-1 mr-6' photo={photo} alt={`photo of ${name}`} data-qa='person-photo' />
        </div>
        <div className='flex flex-col'>
          <p className='text-sm font-bold OneLinkNoTx mb-1'>{name}</p>
          <p className='text-sm'>{desc1}</p>
          {desc2 && <p className='text-sm mt-1'>{desc2}</p>}
        </div>
      </div>
      <div className='col-span-1 md:col-span-4 break-words whitespace-pre-wrap email-phone-wrapper'>
        <EmailPhoneWidget widgetName={widgetName} email={email} phone={phone} />
        {empId !== undefined && (
          <ApplyLoanButton
            className='text-sm text-body-100'
            empId={empId}
            onClick={onStartApplicationClick}
            buttonType='quaternary'
          />
        )}
      </div>
    </div>
  );
};

const LoanOfficerComponentRow = ({ className, loanOfficer }: { className?: string; loanOfficer: LoanOfficer }) => {
  const {
    'role-name': roleName,
    'nmls-id': nmlsId,
    'photo-url': photo,
    name,
    phone,
    email,
    title,
    id: loId,
    'emp-id': empId,
  } = loanOfficer;
  return (
    <AvatarNameContactComponentRow
      className={className}
      photo={photo}
      name={name}
      desc1={title || roleName}
      desc2={`NMLS ${nmlsId}`}
      phone={phone}
      email={email}
      loId={loId}
      empId={empId}
      widgetName='loSupport'
    />
  );
};

const MortgageConsultantComponent = ({ mc }: { mc: LoanTeamMember }) => {
  const { 'role-name': roleName, name, phone, email } = mc;
  return (
    <>
      <hr className='mt-8 mb-6 border-gray-25' />
      <p className='text-sm bold mb-6'>For task list support, contact:</p>
      <div className='flex flex-col md:flex-row lg:flex-col'>
        <div className='flex flex-col mr-0 md:mr-8 lg:mr-0'>
          <p className='text-sm font-bold mb-1.5 OneLinkNoTx'>{name}</p>
          <p className='text-sm capitalize'>{roleName}</p>
        </div>
        <div className='mt-4 md:mt-0 lg:mt-4'>
          <EmailPhoneWidget widgetName='mcSupport' email={email} phone={phone} />
        </div>
      </div>
    </>
  );
};

const MortgageConsultantComponentRow = ({ className, mc }: { className?: string; mc: LoanTeamMember }) => {
  const { name, phone, email } = mc;
  return (
    <AvatarNameContactComponentRow
      className={className}
      name={name}
      desc1='For questions on loan processing and tasks.'
      phone={phone}
      email={email}
      widgetName='mcSupport'
    />
  );
};

const RealtorComponent = ({ realtor }: { realtor: Realtor }) => {
  const { 'role-name': roleName, 'photo-url': photo, name, phone, email, title, 'agency-name': agencyName } = realtor;
  return (
    <>
      <hr className='mt-8 mb-6 border-gray-25' />
      <div className='flex flex-col sm:flex-row lg:flex-col'>
        <div className='flex flex-col sm:flex-row lg:flex-col'>
          <div className='flex mr-0 sm:mr-8 lg:mr-0'>
            <div>
              <Avatar
                className='mr-6'
                photo={photo}
                alt={`Photo of ${name}`}
                data-qa='person-photo'
                style={{ marginTop: defaultMarginTop }}
              />
            </div>
            <div className='h-full'>
              <p className='text-sm font-bold mb-1.5 OneLinkNoTx'>{name}</p>
              <p className='text-sm capitalize mb-1.5'>{title || roleName}</p>
              <p className='text-sm'>{agencyName}</p>
            </div>
          </div>
        </div>
        <div className='mt-4 sm:mt-0 lg:mt-4'>
          <EmailPhoneWidget widgetName='realtorSupport' email={email} phone={phone} />
        </div>
      </div>
    </>
  );
};

const RealtorComponentRow = ({ className, realtor }: { className?: string; realtor: Realtor }) => {
  const { 'role-name': roleName, 'photo-url': photo, name, phone, email, title, id: loId, 'agency-name': agencyName } = realtor;
  return (
    <AvatarNameContactComponentRow
      className={className}
      photo={photo}
      name={name}
      desc1={title || roleName}
      desc2={agencyName}
      phone={phone}
      email={email}
      loId={loId}
      widgetName='realtorSupport'
    />
  );
};

export const GeneralSupport = () => {
  const phoneList = [];
  content.supportPhone && phoneList.push({ phone: content.supportPhone, suffix: content.supportPhoneOption });
  content.supportPhone2 && phoneList.push({ phone: content.supportPhone2, suffix: content.supportPhone2Option });
  return (
    <div className='flex flex-col'>
      <SupportHeadline headline="We're here to help you every step." />
      <p className='title-text-text text-sm bold mb-6'>For active loan application issues, contact:</p>
      <EmailPhoneWidget widgetName='generalSupport' email={content.supportEmail} phoneList={phoneList} />
      {content.supportHours && (
        <div className='mt-6'>
          <SupportHours supportDaysTime={[content.supportHours]} />
        </div>
      )}
    </div>
  );
};

const MobileTableSpacer = ({ className }: { className?: string }) => (
  <div className={cn('my-4 lg:hidden', className)} aria-hidden />
);

export const GeneralSupportRow = () => {
  const phoneList = [];
  content.supportPhone && phoneList.push({ phone: content.supportPhone, suffix: content.supportPhoneOption });
  content.supportPhone2 && phoneList.push({ phone: content.supportPhone2, suffix: content.supportPhone2Option });
  return (
    <div className='lg:grid lg:grid-cols-3'>
      <SupportHeadlineRow
        className='col-span-1 lg:mr-2'
        headline="We're here to help you every step."
        subHeadline='For active loan application issues, contact:'
      />
      <MobileTableSpacer />
      <div className='email-phone-wrapper'>
        <EmailPhoneWidget className='col-span-1' widgetName='generalSupport' email={content.supportEmail} phoneList={phoneList} />
      </div>
      {content.supportHours && (
        <div className='col-span-1 mt-6 lg:mt-0'>
          <SupportHours supportDaysTime={[content.supportHours]} />
        </div>
      )}
    </div>
  );
};

export const RetentionSupport = () => {
  return (
    <div className='flex flex-col'>
      <SupportHeadline headline="We're here to help you every step." />
      <p className='title-text-text text-sm bold mb-6'>For active loan application issues, contact:</p>
      <EmailPhoneWidget widgetName='retentionSupport' email={content.retentionEmail} phone={content.retentionPhone} />
    </div>
  );
};

export const RetentionSupportRow = () => {
  return (
    <div className='lg:grid lg:grid-cols-3'>
      <SupportHeadlineRow
        className='col-span-1 lg:mr-2'
        headline="We're here to help you every step."
        subHeadline='For active loan application issues, contact:'
      />
      <MobileTableSpacer />
      <EmailPhoneWidget
        className='col-span-1'
        widgetName='retentionSupport'
        email={content.retentionEmail}
        phone={content.retentionPhone}
      />
    </div>
  );
};

const ServicingSupport = ({ loanGuid }: { loanGuid: string }) => {
  useFetchServicing(loanGuid);
  const servicing = useAppSelector(state => selectServicing(state, loanGuid));
  const servicingMeta = useAppSelector(state => selectServicingMeta(state, loanGuid));
  const isLoading = ['idle', 'pending'].includes(servicingMeta.loadingStatus);

  const phone = servicing?.servicer?.phone;
  const email = servicing?.servicer?.email || 'customerservice@yourmortgageonline.com';

  return (
    <div className='flex flex-col'>
      <h3 className='text-sm mb-2 bold'>Have a question?</h3>
      <p className='text-sm mb-6'>Our team is happy to assist you.</p>
      <EmailPhoneWidget widgetName='servicingSupport' isLoading={isLoading} email={email} phone={phone} />
    </div>
  );
};

const ServicingSupportRow = ({ loanGuid }: { loanGuid: string }) => {
  const isStyleguide = !!useRouteMatch({
    path: [`${PATHS.styleguide}/*`],
    exact: true,
  });
  const servicing = useAppSelector(state => selectServicing(state, loanGuid));
  const servicingMeta = useAppSelector(state => selectServicingMeta(state, loanGuid));
  const isLoading = !isStyleguide && ['idle', 'pending'].includes(servicingMeta.loadingStatus);

  const phone = servicing?.servicer?.phone;
  const email = servicing?.servicer?.email || 'customerservice@yourmortgageonline.com';

  return (
    <div className='lg:grid lg:grid-cols-4'>
      <SupportHeadlineRow className='col-span-1' headline='Have a question?' subHeadline='Our team is happy to assist you.' />
      <MobileTableSpacer />
      <div className='col-span-1 lg:col-span-2'>
        <div className='email-phone-wrapper'>
          <EmailPhoneWidget widgetName='servicingSupport' isLoading={isLoading} email={email} phone={phone} />
        </div>
      </div>
    </div>
  );
};

export const LoanOfficerSupport = ({ loanOfficer }: { loanOfficer: LoanOfficer }) => {
  return (
    <div className='flex flex-col'>
      <SupportHeadline headline="Need to connect? I'm always available." />
      <LoanOfficerComponent loanOfficer={loanOfficer} />
    </div>
  );
};

const LoanSupportInternal = ({ loan }: { loan: Loan }) => {
  const loanOfficer = loan.team?.lo as LoanOfficer;
  const { isFetching: isLoadingRealtor } = useLoanRealtor(loan['loan-guid']);
  const showMortgageConsultantSupport =
    !!useRouteMatch({
      path: [PATHS.homeLoanTasks, `${PATHS.styleguide}/*`],
      exact: true,
    }) &&
    loan.team?.mc &&
    isTasklistLoan(loan);
  const showRealtorSupport =
    !!useRouteMatch({
      path: [PATHS.homeLoanDetails, PATHS.homeLoanDocuments, PATHS.homeLoanOverview, PATHS.loanCenter, `${PATHS.styleguide}/*`],
      exact: true,
    }) && loan.team?.realtor;
  const showMultipleSupportComponents = showMortgageConsultantSupport || showRealtorSupport;
  return (
    <div className='flex flex-col'>
      <SupportHeadline headline={`Need to connect? ${showMultipleSupportComponents ? "We're" : "I'm"} always available.`} />
      <LoanOfficerComponent loanOfficer={loanOfficer} />
      {showMortgageConsultantSupport && <MortgageConsultantComponent mc={loan.team?.mc as LoanTeamMember} />}
      {isLoadingRealtor ? (
        <>
          <hr className='mt-8 mb-6 border-gray-25' />
          <LoadingContactComponent />
        </>
      ) : (
        showRealtorSupport && <RealtorComponent realtor={loan.team?.realtor as LoanTeamMember} />
      )}
    </div>
  );
};

const LoanSupportInternalRow = ({ loan }: { loan: Loan }) => {
  const loanOfficer = loan.team?.lo as LoanOfficer;
  const { isFetching: isLoadingRealtor } = useLoanRealtor(loan['loan-guid']);
  const showMortgageConsultantSupport =
    !!useRouteMatch({
      path: [PATHS.homeLoanTasks, `${PATHS.styleguide}/*`],
      exact: true,
    }) &&
    loan.team?.mc &&
    isTasklistLoan(loan);
  const showRealtorSupport =
    !!useRouteMatch({
      path: [PATHS.homeLoanDetails, PATHS.homeLoanDocuments, PATHS.homeLoanOverview, PATHS.loanCenter, `${PATHS.styleguide}/*`],
      exact: true,
    }) && loan.team?.realtor;
  const showMultipleSupportRows = showMortgageConsultantSupport || showRealtorSupport;
  const firstRowClassNames =
    'col-span-1 lg:col-span-2 border-gray-25 border-b lg:border-b-0 lg:border-r mb-6 lg:mb-0 pb-6 lg:pb-0 mr-0 lg:mr-6 pr-0 lg:pr-6';
  const secondRowClassNames = 'border-gray-25 col-span-1 lg:col-span-2';
  return (
    <>
      <div className={showMultipleSupportRows ? 'grid grid-cols-1 lg:grid-cols-5' : 'lg:grid grid-cols-1 lg:grid-cols-9'}>
        <SupportHeadlineRow
          className={showMultipleSupportRows ? 'col-span-1 lg:pr-3 mb-3 lg:mb-0' : 'col-span-1 lg:col-span-2 lg:pr-3'}
          headline='Need to connect?'
          subHeadline={`${showMultipleSupportRows ? "We're" : "I'm"} always available.`}
        />
        {showMortgageConsultantSupport && (
          <MortgageConsultantComponentRow className={firstRowClassNames} mc={loan.team?.mc as LoanTeamMember} />
        )}
        <LoanOfficerComponentRow
          className={
            showMortgageConsultantSupport
              ? secondRowClassNames
              : showRealtorSupport
              ? firstRowClassNames
              : 'col-span-1 lg:col-span-4 xl:-ml-3.5'
          }
          loanOfficer={loanOfficer}
        />
        {isLoadingRealtor ? (
          <LoadingContactComponentRow />
        ) : (
          showRealtorSupport && (
            <RealtorComponentRow realtor={loan.team?.realtor as LoanTeamMember} className={secondRowClassNames} />
          )
        )}
      </div>
    </>
  );
};

export const LoanSupportRightRail = ({ loan }: { loan: Loan }) => {
  const loanOfficer = loan.team?.lo;
  const canShowServicing = !!useRouteMatch({
    path: [PATHS.homeLoanServicing, `${PATHS.styleguide}/*`],
    exact: true,
  });

  if (isFundedLoan(loan) && canShowServicing) {
    return <ServicingSupport loanGuid={loan['loan-guid']} />;
  } else if (loanOfficer?.status === 'active') {
    return <LoanSupportInternal loan={loan} />;
  } else if (loanOfficer?.status === 'inactive' && content.retentionEmail && content.retentionPhone) {
    return <RetentionSupport />;
  }

  return <GeneralSupport />;
};

export const LoanSupportFooter = ({ loan }: { loan: Loan }) => {
  const loanOfficer = loan.team?.lo;
  const canShowServicing = !!useRouteMatch({
    path: [PATHS.homeLoanServicing, `${PATHS.styleguide}/*`],
    exact: true,
  });

  if (isFundedLoan(loan) && canShowServicing) {
    return <ServicingSupportRow loanGuid={loan['loan-guid']} />;
  } else if (loanOfficer?.status === 'active') {
    return <LoanSupportInternalRow loan={loan} />;
  } else if (loanOfficer?.status === 'inactive' && content.retentionEmail && content.retentionPhone) {
    return <RetentionSupportRow />;
  }

  return <GeneralSupportRow />;
};

export const WrapLoanSupportFooter = ({ children }: { children: React.ReactNode }) => {
  const embed = useEmbed();
  return <div className={cn('py-5 lg:pt-7 lg:pb-9', { 'border-b border-gray-25': !embed })}>{children}</div>;
};

export const LoanSupport = ({ loan, rightRail = true }: { loan: Loan; rightRail?: boolean }) => {
  if (rightRail) {
    return <LoanSupportRightRail loan={loan} />;
  }

  return (
    <WrapLoanSupportFooter>
      <LoanSupportFooter loan={loan} />
    </WrapLoanSupportFooter>
  );
};
