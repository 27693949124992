import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import './Carousel.scss';
import Button from '../button/Button';

const CarouselTile = ({ color }: { color: string }) => {

  const handleClick = () => {
    console.log(`clicked on ${color} tile`);
  };

  return (
    <div className='keen-slider__slide flex items-center' style={{ height: '200px' }} tabIndex={0} role='button' onClick={handleClick}>
      <div className='zoom-tile' style={{ backgroundColor: `${color}` }} />
    </div>
  );
};

const Carousel = () => {
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      mode: 'snap',
      slides: {
        perView: 1,
        spacing: 0,
      },
      breakpoints: {
        '(min-width: 640px)': {
          slides: { perView: 2, spacing: 5 },
        },
        '(min-width: 990px)': {
          slides: { perView: 4, spacing: 10 },
        },
      }
    },
    [],
  );

  const handlePrev = () => {
    instanceRef.current?.prev();
  };

  const handleNext = () => {
    instanceRef.current?.next();
  };

  return (
    <div className='flex flex-row items-center'>

      <Button
        className='flex items-center justify-center bg-white w-10 h-10 rounded-full shadow-lg-full relative z-10 left-5'
        buttonType='icon'
        iconPlacement='left'
        iconName='chevron-left-small'
        text='previous'
        onClick={handlePrev}
        tabIndex={0}
      />

      <div ref={sliderRef} className='keen-slider'>
        <CarouselTile color='red' />
        <CarouselTile color='blue' />
        <CarouselTile color='yellow' />
        <CarouselTile color='green' />
        <CarouselTile color='purple' />
      </div>

      <Button
        className='flex items-center justify-center bg-white w-10 h-10 rounded-full shadow-lg-full relative z-10 right-5'
        buttonType='icon'
        iconPlacement='left'
        iconName='chevron-right-small'
        text='next'
        onClick={handleNext}
        tabIndex={0}
      />
    </div>
  );
};

export default Carousel;
