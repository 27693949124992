import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import api from '../../../features/api';
import { Task } from '../../../features/tasks/types';
import { useAdditionalTaskDetails } from '../../../hooks/useAdditionalTaskDetails';
import { useTaskText } from '../../../hooks/useTaskText';
import { log } from '../../../utils/logger';
import nextId from '../../../utils/nextId';
import timezone from '../../../utils/timezone';
import Form from '../../ui/form/Form';
import FormButtonComponent from '../../ui/form/FormButtonComponent';
import FormGroup from '../../ui/form/FormGroup';
import { FormGroupElement } from '../../ui/form/IFormGroup';
import Icon from '../../ui/icon/Icon';
import RadioButtonsGroup from '../../ui/radioButton/RadioButtonsGroup';
import { AdditionalTaskDetails, DefaultTaskBodyDescription } from '../task/Task';
import { LoxDisclaimerCheckbox } from './LoxTaskBody';
import UnsubmittedModal from '../task/UnsubmittedModal';
import './Lox.css';
import { selectAppLanguage } from '../../../features/app/appSlice';
import { useAppSelector } from '../../../hooks/hooks';
import { DEFAULT_LANGUAGE } from '../../../utils/multilingual';
import { serializeError } from '../../../utils/serializeError';
import { setDataLayer } from '../../../features/analytics/setDataLayer';

interface Props {
  loanGuid: string;
  task: Task<'credit_inquiry_lox'>;
  setAccordionActive?: (active: boolean) => void;
}

interface CreditCheckForm {
  explanation?: string;
  newAccount: string;
}

const initCreditCheckForm: CreditCheckForm = {
  explanation: undefined,
  newAccount: 'No',
};

interface FormState {
  byExplanationId: Record<string, CreditCheckForm>;
  checked: boolean;
}

const LoxCreditInquiryForm = ({ loanGuid, task, setAccordionActive }: Props) => {
  const [formId] = useState(nextId('lox-credit-inquiry-form-'));
  const [explanationErrors, setExplanationErrors] = useState<{ [key: string]: string | undefined }>({});
  const [checkboxError, setCheckboxError] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string>();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  const additionalDetails = useAdditionalTaskDetails(task, loanGuid);
  const taskDescription = useTaskText(task, 'description', loanGuid) as string;
  const { 'task-id': taskId, 'task-type': taskType, 'task-title': taskTitle, 'task-explanations': taskExplanations } = task;
  const taskExplanationIds = taskExplanations?.map(exp => exp.taskExplanationId) || [];
  const appLanguage = useAppSelector(selectAppLanguage) || DEFAULT_LANGUAGE;
  const disclaimer = task['custom-task-meta']?.['disclaimer-text']?.[appLanguage];

  const formStateRef = useRef<FormState>({
    byExplanationId: {},
    checked: false,
  });

  const onValueChange = (expId: string, event: ChangeEvent<FormGroupElement>) => {
    const value = event.target.value;
    if (!formStateRef.current.byExplanationId[expId]) {
      formStateRef.current.byExplanationId[expId] = { ...initCreditCheckForm };
    }
    formStateRef.current.byExplanationId[expId].explanation = value;
    setFormDirty(true);

    const hasError = checkFormErrors(false);
    setDisableSubmit(hasError);
  };

  const onExplanationBlur = () => {
    const hasError = checkFormErrors(true);
    setDisableSubmit(hasError);
  };

  const onNewAccountChange = (expId: string, value: string) => {
    if (!formStateRef.current.byExplanationId[expId]) {
      formStateRef.current.byExplanationId[expId] = { ...initCreditCheckForm };
    }
    formStateRef.current.byExplanationId[expId].newAccount = value;
    setFormDirty(true);

    const hasError = checkFormErrors(true);
    setDisableSubmit(hasError);
  };

  const onCheckboxChanged = (value: boolean) => {
    formStateRef.current.checked = value;
    setCheckboxError(value ? undefined : 'This must be checked before you can continue.');
    setFormDirty(true);

    const hasError = checkFormErrors(true);
    setDisableSubmit(hasError);
  };

  const checkFormErrors = (flagError: boolean) => {
    let hasError = false;
    setSubmitError(undefined);

    const newErrorState: { [key: string]: string | undefined } = {};
    const formState = formStateRef.current;
    taskExplanationIds.forEach(taskExpId => {
      if (!formState.byExplanationId?.[taskExpId]?.explanation) {
        if (flagError) {
          newErrorState[taskExpId] = 'An explanation is required.';
        }
        hasError = true;
      } else {
        newErrorState[taskExpId] = undefined;
      }
    });
    setExplanationErrors(newErrorState);

    if (!formState.checked && flagError) {
      setCheckboxError('This must be checked before you can continue.');
    }
    hasError = hasError || !formState.checked;

    return hasError;
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setDataLayer('taskInfo', { taskId, taskType });
    log({ loanGuid, taskId, message: `Attempting to submit LOX credit inquiry ${JSON.stringify({ taskType, taskTitle })}` });
    try {
      const hasError = checkFormErrors(true);
      if (hasError) {
        setDisableSubmit(true);
        log({ loanGuid, taskId, message: `LOX credit inquiry form has errors ${JSON.stringify({ taskType, taskTitle })}` });
        return;
      }
      setSubmitting(true);
      const formState = formStateRef.current;
      const taskExplanations = taskExplanationIds.map(taskExpId => {
        return {
          taskExplanationId: taskExpId,
          explanation: formState.byExplanationId[taskExpId].explanation as string,
          newAccount: formState.byExplanationId[taskExpId].newAccount === 'Yes',
        };
      });
      await api.postTaskDocument(loanGuid, taskId, {
        'task-explanations': taskExplanations,
        timezone: timezone(),
        source: task.source,
      });
      setFormDirty(false);
      setDisableSubmit(true);
      setSubmitSuccess(true);
      log({ loanGuid, taskId, message: `LOX credit inquiry submit success ${JSON.stringify({ taskType, taskTitle })}` });
    } catch (error) {
      setSubmitError('Submit Error. Please try again later.')
      log({ loanGuid, taskId, level: 'error', message: `LOX credit inquiry submit failed ${JSON.stringify({ taskType, taskTitle })} ${serializeError(error)}` });
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmitAbandon = () => {
    setFormDirty(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <DefaultTaskBodyDescription taskDescription={taskDescription} />
      {additionalDetails && <AdditionalTaskDetails additionalDetails={additionalDetails} />}
      <UnsubmittedModal
        isDirty={formDirty}
        onAbandon={onSubmitAbandon}
        setAccordionActive={setAccordionActive}
        submitType='explanations'
      />
      {taskExplanations?.length > 0 && taskExplanations.map(exp => {
        const expId = exp.taskExplanationId;
        const creditor = exp.additionalData.creditor;
        const inquiryDate = exp.additionalData.inquiryDate;
        return (
          <div className='mt-6' key={exp.taskExplanationId}>
            <span className='font-bold'>{creditor} inquiry from {inquiryDate}</span>
            <FormGroup
              className='my-4'
              required={true}
              label='Explanation'
              id={`${formId}-${creditor}-explanation`}
              name={`${creditor}-explanation`}
              type='textarea'
              error={explanationErrors[expId]}
              onChange={event => onValueChange(expId, event)}
              onBlur={onExplanationBlur}
              disabled={submitting || submitSuccess}
            />
            <RadioButtonsGroup
              className='my-4 text-sm lg:text-base'
              description='Did this inquiry result in a new account?'
              labels={['No', 'Yes']}
              groupName={`${expId}-newAccount`}
              onChange={value => onNewAccountChange(expId, value)}
              disabled={submitting || submitSuccess}
            />
          </div>
        );
      })}
      <LoxDisclaimerCheckbox error={checkboxError} onChange={onCheckboxChanged} disclaimer={disclaimer} disabled={submitting || submitSuccess} />
      <div className='mt-6'>
        {submitSuccess ?
          <div className='flex justify-center items-center'>
            <Icon name='check-tick' className='text-ok mr-2' size='0.75rem' />
            <p>Your submission was successful</p>
          </div>
        : <FormButtonComponent
            id={`${formId}-submit-button`}
            buttonContainerClassName='mt-0 w-full md:w-fit-content'
            className='w-full md:w-fit-content'
            error={submitError}
            loading={submitting}
            disabled={disableSubmit}
          >
            Submit explanations
          </FormButtonComponent>
        }
      </div>
    </Form>
  );
};

export default LoxCreditInquiryForm;
