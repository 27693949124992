import { ReactNode } from 'react';

export const textBadgeTypes = [
  'ok',
  'ok-secondary',
  'critical',
  'critical-secondary',
  'warning',
  'inactive',
  'action',
  'action-secondary',
] as const;
export type iTextBadge = typeof textBadgeTypes[number];

type TextOrChildren = { text: string; children?: never } | { children: ReactNode; text?: never };

export type TextBadgeProps = TextOrChildren &
  React.StyleHTMLAttributes<HTMLSpanElement> & {
    type?: iTextBadge;
  };
