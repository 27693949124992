import rateAlertImage from '../../images/rate-alert-logo.svg';
import Button from '../ui/button/Button';
import useFeatureEnabled from '../../hooks/useFeatureEnabled';
import { Loan } from '../../features/loans/types';
import { useFetchRateAlert } from '../../features/rateAlert/useFetchRateAlert';
import Alert from '../ui/alert/Alert';
import { usdAmount } from '../../utils/amount';
import { formatPercent } from '../../utils/percent';
import { rateAlertEdit } from '../../utils/grLinks';
import { log } from '../../utils/logger';
import './RateAlert.scss';
import { isEmpty } from '../../utils/object';
import { RateAlert as RateAlertModel } from '../../features/rateAlert/types';
import Icon from '../ui/icon/Icon';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import cn from 'classnames';

export const RateAlertPresentational = ({ loan, rateAlert }: { loan: Loan; rateAlert?: RateAlertModel }) => {
  const { 'loan-guid': loanGuid } = loan;
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const onEditRateAlert = (invitationNumber: string) => {
    log({ loanGuid, message: `Edit rate alert invitation ${invitationNumber}` });
  };

  return rateAlert ? (
    <div className='w-full rate-alert mt-12 flex flex-col'>
      <div className='border border-inactive-25 pb-2 flex flex-col rounded-xl'>
        <Button
          href={rateAlertEdit(rateAlert.invitationNumber)}
          target='_blank'
          className='rounded-t-xl px-6 pb-4 flex justify-between no-underline'
          style={{ marginTop: '28px' }}
          onClick={() => onEditRateAlert(rateAlert.invitationNumber)}
        >
          <img className='h-5 flex self-center' src={rateAlertImage} alt='Rate Alert' />
          <Icon name='chevron-right-small' size='14px' className='align-middle flex self-center mr-2' />
        </Button>
        <div className='flex flex-col px-6'>
          {!rateAlert && (
            <Alert
              className='my-4'
              type='error'
              showClose={false}
              description='Rate alert is currently unavailable. If the issue persists please contact support.'
            />
          )}
          <>
            <div className='flex items-baseline mt-2 gap-5'>
              <div className='flex flex-col'>
                <p className='text-sm'>Current interest rate</p>
                <p className='text-xl font-bold'>{formatPercent(rateAlert.loanInfo.interestRate, 2)}</p>
              </div>
              <div className='flex flex-col'>
                <p className='text-sm'>Target rate</p>
                <p className='text-xl font-bold'>{formatPercent(rateAlert.data.targetRate, 2)}</p>
              </div>
            </div>
            <div className='flex w-100 mt-4 p-4 bg-input-10 flex-col rounded-xl'>
              <div className='flex flex-row gap-5 justify-between'>
                <div className='flex flex-col'>
                  <p className='text-sm'>Annual savings</p>
                  <p className='text-sm font-bold'>{usdAmount(rateAlert.monitorInfo.estimatedYearlySavingsAmount, false)}</p>
                </div>
                <div className='flex flex-col'>
                  <p className='text-sm'>Monthly savings</p>
                  <p className='text-sm font-bold'>{usdAmount(rateAlert.monitorInfo.estimatedYearlySavingsAmount / 12, false)}</p>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className='px-6 pb-4'>
          <Button
            className='flex justify-start pt-3 no-underline leading-5 font-bold'
            onClick={() => setShowDisclaimer(!showDisclaimer)}
          >
            <p className='text-action-50 disclaimers-title'>Disclaimers</p>
            <Icon
              name='chevron-down-small'
              size='10px'
              className={cn('ml-2 disclaimers-icon text-action-50 self-center', { 'disclaimers-shown': showDisclaimer })}
            />
          </Button>
          <AnimateHeight height={showDisclaimer ? 'auto' : 0} duration={250}>
            <p className='mt-2 text-xs' style={{ fontStretch: 'condensed' }}>
              {`Based on your ${usdAmount(
                rateAlert.loanInfo.monthlyPayment,
              )} monthly principal and interest payment and ${formatPercent(
                rateAlert.loanInfo.interestRate,
              )} interest rate. Savings, if any, vary based on the consumer's credit profile, interest rate availability, and other factors.`}
            </p>
          </AnimateHeight>
        </div>
      </div>
    </div>
  ) : null;
};

const RateAlert = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid } = loan;
  const rateAlertEnabled = useFeatureEnabled('rate-alert');
  const { rateAlert, rateAlertFetching, rateAlertError, rateAlertHasData } = useFetchRateAlert(loanGuid);

  if (
    !rateAlertEnabled ||
    rateAlertFetching ||
    (rateAlertHasData && !rateAlertError && !rateAlert) ||
    (rateAlertHasData && !rateAlertError && rateAlert && isEmpty(rateAlert.data))
  ) {
    return null;
  }

  const showError = rateAlertError && !rateAlertHasData;

  return <RateAlertPresentational loan={loan} rateAlert={showError ? undefined : rateAlert} />;
};

export default RateAlert;
