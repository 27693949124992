import { LoanConfig } from '../components/loan/loanCard/ILoanCard';
import { LOAN_STATUS_MAP } from '../components/loan/loanStatusInfo/LoanStatus';
import { linkTo, PATHS } from '../components/routes/paths';
import { AlertType } from '../components/ui/alert/IAlert';
import { selectAppLanguage, selectGlobalAdtrk } from '../features/app/appSlice';
import { isFundedLoan } from '../features/loans/helpers';
import type { Loan } from '../features/loans/types';
import { LoanMilestones } from '../features/loans/types';
import type { User } from '../features/user/types';
import { selectUser } from '../features/user/userSlice';
import { autodmApplication, dmxApplication } from '../utils/grLinks';
import { isBusinessHours } from '../utils/isBusinessHours';
import { isUserBorrower } from '../utils/isUserBorrower';
import { useAppSelector } from './hooks';
import { useMultilingual } from './useMultilingual';

export const useLoanConfig = (loan: Loan): LoanConfig => {
  const multilingual = useMultilingual();
  const appLanguage = useAppSelector(selectAppLanguage);
  const langPref = multilingual && appLanguage ? appLanguage : undefined;
  const adtrk = useAppSelector(selectGlobalAdtrk);
  const currentUser = useAppSelector(selectUser) as User;
  const isBusinessHour = isBusinessHours();
  let milestoneText = undefined;
  let loanTypeText = undefined;
  let actionLabel = undefined;
  let actionHref = undefined;
  let alertType: AlertType = 'info';
  let alertText = undefined;
  let alertButtonText = undefined;
  let alertButtonHref = undefined;
  let showTracker = true;
  let trackerValue = 5;
  let isVA = false;
  let isRDC = false;

  if (loan) {
    const {
      'gr-loan-guid': grLoanGuid,
      'loan-milestone': loanMilestone,
      'application-status': applicationStatus,
      'digital-mortgage?': isFromDMX,
      coborrower: coBorrower,
      'loan-guid': loanGuid,
      'loan-inaccessible?': loanInaccessible,
      'same-day-status': sameDayStatus,
      'originating-app': originatingApp,
    } = loan;

    isVA = loan['va?'];
    isRDC = loan['lead-source'] === 'rdc';

    loanTypeText = loan['refinance?'] ? 'Refinance' : 'Purchase';

    if (applicationStatus === 'completed' || applicationStatus === 'not-editable') {
      actionHref = linkTo(PATHS.homeLoanOverview, { guid: loanGuid });
      if ([LoanMilestones.CLEAR_TO_CLOSE, LoanMilestones.CLOSED].includes(loanMilestone)) {
        actionLabel = 'View completed tasks';
      } else {
        actionLabel = 'Complete tasks';
      }
    }

    if (applicationStatus === 'not-editable' || (!isFromDMX && applicationStatus !== 'completed')) {
      alertText = 'We are setting up your loan. If the wait is longer than 30 minutes, reach out to your loan team.';
    } else if (applicationStatus === 'editable') {
      if (coBorrower && isUserBorrower(currentUser, coBorrower)) {
        alertType = 'info';
        alertText = 'The primary borrower must complete the loan application before you can proceed.';
      } else {
        // Defaults to DMX application link if no `originatingApp` value is present
        const applicationLink =
          originatingApp === 'alp' ? autodmApplication(grLoanGuid) : dmxApplication({ 'gr-loan-guid': grLoanGuid, langPref, adtrk });
        actionLabel = 'Finish application';
        actionHref = applicationLink;
        alertType = 'warning';
        alertText = 'Please complete your application to proceed with your loan.';
        alertButtonText = 'Finish application';
        alertButtonHref = applicationLink;
      }
    } else if (applicationStatus === 'timed-out') {
      alertText = 'Your application session has expired. A loan expert will reach out shortly to assist you.';
    } else if (sameDayStatus === 'not-eligible') {
      actionHref = undefined;
      actionLabel = undefined;
      alertType = 'info';
      if (isBusinessHour) {
        alertText =
          "No need to do anything. Your application is being reviewed and we'll call within the next 30 minutes to talk options.";
      } else {
        alertText =
          "No need to do anything. Your application is being reviewed and we'll call during our business hours to talk options.";
      }
    }

    milestoneText = LOAN_STATUS_MAP[loanMilestone]?.title || 'N/A';

    if (isFundedLoan(loan)) {
      showTracker = false;
      actionHref = linkTo(PATHS.homeLoanServicing, { guid: loanGuid });
      actionLabel = 'Get servicing information';
    } else if (loanMilestone === LoanMilestones.APPLICATION) {
      trackerValue = 14;
    } else if (loanMilestone === LoanMilestones.PREAPPROVAL) {
      trackerValue = 28;
    } else if (loanMilestone === LoanMilestones.PREAPPROVED) {
      trackerValue = 42;
    } else if (loanMilestone === LoanMilestones.CONDITIONAL_APPROVAL) {
      trackerValue = 57;
    } else if (loanMilestone === LoanMilestones.CONDITIONALLY_APPROVED) {
      trackerValue = 72;
    } else if (loanMilestone === LoanMilestones.CLEAR_TO_CLOSE) {
      trackerValue = 86;
    } else if (loanMilestone === LoanMilestones.CLOSED) {
      trackerValue = 100;
    }

    if (loanInaccessible === true) {
      actionHref = undefined;
      alertType = 'info';
      alertText = 'No need to do anything. Your application is being reviewed and we’ll call you to talk options.';
    }
  }

  return {
    isRDC,
    isVA,
    showTracker,
    trackerValue,
    milestoneText,
    loanTypeText,
    actionLabel,
    actionHref,
    alertText: alertText ? alertText : undefined,
    alertType: alertType ? alertType : undefined,
    alertButtonText: alertButtonText ? alertButtonText : undefined,
    alertButtonHref: alertButtonHref ? alertButtonHref : undefined,
  };
};
