import { ChangeEvent, FormEvent, useState } from 'react';
import api from '../../../features/api';
import { Task } from '../../../features/tasks/types';
import { useTaskText } from '../../../hooks/useTaskText';
import nextId from '../../../utils/nextId';
import Form from '../../ui/form/Form';
import FormButtonComponent from '../../ui/form/FormButtonComponent';
import FormGroup from '../../ui/form/FormGroup';
import { FormGroupElement } from '../../ui/form/IFormGroup';
import { AdditionalTaskDetails, DefaultTaskBodyDescription } from '../task/Task';
import UnsubmittedModal from '../task/UnsubmittedModal';
import timezone from '../../../utils/timezone';
import Icon from '../../ui/icon/Icon';
import { log } from '../../../utils/logger';
import { LoxDisclaimerCheckbox } from './LoxTaskBody';
import { useAdditionalTaskDetails } from '../../../hooks/useAdditionalTaskDetails';
import { serializeError } from '../../../utils/serializeError';
import { setDataLayer } from '../../../features/analytics/setDataLayer';

interface Props {
  loanGuid: string;
  task: Task<'data_lox'>;
  setAccordionActive?: (active: boolean) => void;
}

const LoxStandardForm = ({ loanGuid, task, setAccordionActive }: Props) => {
  const [formId] = useState(nextId('lox-standard-form-'));
  const [explanationError, setExplanationError] = useState<string>();
  const [checkboxError, setCheckboxError] = useState<string>();
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string>();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  const additionalDetails = useAdditionalTaskDetails(task, loanGuid);
  const taskDescription = useTaskText(task, 'description', loanGuid) as string;
  const { 'task-id': taskId, 'task-type': taskType, 'task-title': taskTitle } = task;

  const [formState] = useState({
    explanation: '',
    checked: false,
  });

  const onCheckboxChanged = (value: boolean) => {
    formState.checked = value;
    setCheckboxError(value ? undefined : 'This must be checked before you can continue.');
    setFormDirty(true);
    const hasError = checkFormErrors(false);
    setDisableSubmit(hasError);
  };

  const onValueChange = (event: ChangeEvent<FormGroupElement>) => {
    const value = event.target.value;
    formState.explanation = value;
    setFormDirty(true);
    setExplanationError(value ? undefined : 'An explanation is required.');
    const hasError = checkFormErrors(false);
    setDisableSubmit(hasError);
  };

  const onExplanationBlur = () => {
    const hasError = checkFormErrors(false);
    setDisableSubmit(hasError);
  };

  const checkFormErrors = (flagError: boolean) => {
    let hasError = false;
    setSubmitError(undefined);

    if (!formState.explanation && flagError) {
      setExplanationError('An explanation is required.');
    }
    hasError = hasError || !formState.explanation;

    if (!formState.checked && flagError) {
      setCheckboxError('This must be checked before you can continue.');
    }
    hasError = hasError || !formState.checked;

    return hasError;
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setDataLayer('taskInfo', { taskId, taskType });
    log({ loanGuid, taskId, message: `Attempting to submit LOX standard ${JSON.stringify({ taskType, taskTitle })}` });
    try {
      const hasError = checkFormErrors(true);
      if (hasError) {
        setDisableSubmit(true);
        log({ loanGuid, taskId, message: `LOX standard form has errors ${JSON.stringify({ taskType, taskTitle })}` });
        return;
      }
      setSubmitting(true);
      await api.postTaskDocument(loanGuid, taskId, {
        explanation: formState.explanation,
        timezone: timezone(),
        source: task.source,
      });
      setFormDirty(false);
      setDisableSubmit(true);
      setSubmitSuccess(true);
      log({ loanGuid, taskId, message: `LOX standard submit success ${JSON.stringify({ taskType, taskTitle })}` });
    } catch (error) {
      setSubmitError('Submit Error. Please try again later.')
      log({ loanGuid, taskId, level: 'error', message: `LOX standard submit failed ${JSON.stringify({ taskType, taskTitle })} ${serializeError(error)}` });
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmitAbandon = () => {
    setFormDirty(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <DefaultTaskBodyDescription taskDescription={taskDescription} />
      {additionalDetails && <AdditionalTaskDetails additionalDetails={additionalDetails} />}
      <UnsubmittedModal
        isDirty={formDirty}
        onAbandon={onSubmitAbandon}
        setAccordionActive={setAccordionActive}
        submitType='explanations'
      />
      <FormGroup
        required={true}
        label='Explanation'
        id={`${formId}-explanation`}
        name='explanation'
        type='textarea'
        onChange={onValueChange}
        onBlur={onExplanationBlur}
        error={explanationError}
        disabled={submitting || submitSuccess}
      />
      <LoxDisclaimerCheckbox error={checkboxError} onChange={onCheckboxChanged} disabled={submitting || submitSuccess} />
      <div className='mt-6'>
        {submitSuccess ?
          <div className='flex justify-center items-center'>
            <Icon name='check-tick' className='text-ok mr-2' size='0.75rem' />
            <p>Your submission was successful</p>
          </div>
        : <FormButtonComponent
            id={`${formId}-submit-button`}
            buttonContainerClassName='mt-0 w-full md:w-fit-content'
            className='w-full md:w-fit-content'
            error={submitError}
            loading={submitting}
            disabled={disableSubmit}
          >
            Submit explanations
          </FormButtonComponent>
        }
      </div>
    </Form>
  );
};

export default LoxStandardForm;
