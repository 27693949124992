import cn from 'classnames';
import { sendEvent } from '../../features/analytics/sendEvent';
import appleAppStoreImage from '../../images/apple-app-store.png';
import appleAppStoreBlackImage from '../../images/apple-app-store-black.svg';
import googlePlayStoreImage from '../../images/google-play-store.png';
import googlePlayStoreBlackImage from '../../images/google-play-store-black.svg';
import holdingPhoneImageMobile from '../../images/hand-holding-phone-mobile.jpg';
import holdingPhoneImage from '../../images/hand-holding-phone.jpg';
import { ReactComponent as RateLogoWhite } from '../../images/logo-rate-white.svg';
import { ReactComponent as RateLogo } from '../../images/logo-rate.svg';
import holdingCoffeeImage from '../../images/woman-holding-coffee.jpg';
import { mainSite } from '../../utils/grLinks';
import { log } from '../../utils/logger';
import Link from '../ui/link/Link';
import styles from './RateAppAd.module.css';
import { useAppSelector } from '../../hooks/hooks';
import { selectGlobalAdtrk } from '../../features/app/appSlice';
import { selectCurrentLo } from '../../features/user/selectCurrentLo';
import rateAppImageLarge from '../../images/rate-app-large.png';
import rateAppImageMed from '../../images/rate-app-med.png';
import rateAppImageSmall from '../../images/rate-app-small.png';

// rateAppAd1 - Old Desktop rate app ad
// rateAppAd2 - Right Rail rate app ad
// rateAppAd3 - New Desktop rate app ad
// rateAppAd4 - New Desktop rate app ad wide design - only in styleguide
type RateAppAdIds = 'rateAppAd1' | 'rateAppAd2' | 'rateAppAd3' |'rateAppAd4' ;
type RateAppAdActions = 'appleStore' | 'googlePlay';
type RateAppAdLocations = 'loanCenter' | 'rightRail' | 'styleguide';

const useLink = ({ location }: { location: RateAppAdLocations }) => {
  const adtrk = useAppSelector(selectGlobalAdtrk);
  const lo = useAppSelector(selectCurrentLo);
  return mainSite('https://www.rate.com/rate-app', { adtrk, loid: lo?.['emp-id'], icid: `rateapp:myaccount:${location}` });
};

const triggerEvent = ({ id, action, location }: { id: RateAppAdIds; action: RateAppAdActions; location: RateAppAdLocations }) => {
  log({ level: 'info', message: `User clicked Rate App Ad ${JSON.stringify({ id, action, location })}` });
  sendEvent('rateAppAdClick', { id, action, location });
};

export const RateAppAd3 = ({ location }: { location: RateAppAdLocations }) => {
  const id = 'rateAppAd3';
  const link = useLink({ location });
  return (
    <div className='flex flex-col lg:justify-between lg:flex-row relative items-center'>
      {/* background */}
      <div
        className={cn('rounded-xl absolute w-full left-0 bottom-0', styles['background'])}
        style={{
          background: 'linear-gradient(135deg, #f4f8fd 0%, #deeaf9 100%)',
          zIndex: -1,
        }}
      />
      {/* text */}
      {/* padding top should be the half of background screen height offset */}
      <div className='flex flex-col mx-6 mt-6 lg:mt-1.5'>
        <p className={cn('whitespace-normal font-bold mb-4', styles['title-text'])}>Download the Rate App</p>
        <p className={cn('mb-4', styles['text-section'])}>The only fintech app that combines <span className='font-bold'>financial</span> and <span className='font-bold'>personal</span> wellness so you can live your best life.</p>
        <div className='flex flex-row gap-4'>
          <Link href={link} target='_blank' onClick={() => triggerEvent({ id, action: 'appleStore', location })}>
            <img
              src={appleAppStoreBlackImage}
              width='123'
              height='41'
              alt='Download on the Apple App Store'
            />
          </Link>
          <Link href={link} target='_blank' onClick={() => triggerEvent({ id, action: 'googlePlay', location })}>
            <img
              src={googlePlayStoreBlackImage}
              width='136'
              height='41'
              alt='Get it on Google Play'
            />
          </Link>
        </div>
      </div>
      {/* image */}
      <div className='flex lg:hidden justify-center mt-8'>
        <img src={rateAppImageSmall} width='272' alt='Rate App' />
      </div>
      <div className='hidden lg:flex justify-end mr-4'>
        <img src={rateAppImageLarge} alt='Rate App' />
      </div>
    </div>
  );
};

export const RateAppAd4 = ({ location }: { location: RateAppAdLocations }) => {
  const id = 'rateAppAd4';
  const link = useLink({ location });
  return (
    <div className='flex flex-col lg-orig:justify-between lg-orig:flex-row relative'>
      {/* background */}
      <div
        className={cn('rounded-xl absolute w-full left-0 bottom-0', styles['full-ad-background'])}
        style={{
          backgroundColor: '#deeafa',
          zIndex: -1,
        }}
      />
      {/* text */}
      <div className='flex flex-col mx-6 mt-8 lg-orig:ml-12 lg-orig:mr-0 lg-orig:mt-20'>
        <div className='rounded-full font-bold text-white text-xl px-3 py-2 w-fit-content mb-4' style={{ backgroundColor: '#2175da' }}>Rate App</div>
        <p className={cn('whitespace-normal lg-orig:whitespace-nowrap font-bold mb-2', styles['full-ad-title-text'])}>Financial wellness.</p>
        <p className={cn('whitespace-normal lg-orig:whitespace-nowrap font-bold mb-8', styles['full-ad-title-text'])}>Personal wellness.</p>
        <p className={cn('mb-4', styles['full-ad-text-section'])}>The only fintech app that combines <span className='font-bold'>financial</span> and <span className='font-bold'>personal</span> wellness so you can live your best life.</p>
        <div className='flex flex-row gap-4'>
          <Link href={link} target='_blank' onClick={() => triggerEvent({ id, action: 'appleStore', location })}>
            <img
              src={appleAppStoreBlackImage}
              width='164'
              height='55'
              alt='Download on the Apple App Store'
              className={cn(styles['apple-store-image'], styles['sm-apple-store-image'])}
            />
          </Link>
          <Link href={link} target='_blank' onClick={() => triggerEvent({ id, action: 'googlePlay', location })}>
            <img
              src={googlePlayStoreBlackImage}
              width='182'
              height='55'
              alt='Get it on Google Play'
              className={cn(styles['google-play-image'], styles['sm-google-play-image'])}
            />
          </Link>
        </div>
      </div>
      {/* image */}
      <div className={cn(styles['large-image'])}>
        <img src={rateAppImageLarge} alt='Rate App' />
      </div>
      <div className={cn(styles['med-image'])}>
        <img src={rateAppImageMed} alt='Rate App' />
      </div>
      <div className={cn(styles['small-image'])}>
        <img src={rateAppImageSmall} alt='Rate App' />
      </div>
    </div>
  );
};

export const RateAppAd1 = ({ location }: { location: RateAppAdLocations }) => {
  const id = 'rateAppAd1';
  const link = useLink({ location });
  return (
    <div
      className='rounded-xl overflow-hidden flex flex-col sm:flex-row sm:justify-between'
      style={{ backgroundColor: '#ECE4D4', minHeight: '232px' }}
    >
      <div className='px-5 py-6 flex items-center'>
        <div>
          <div className='sm:mb-5 -mt-1.5'>
            <h3 className={cn(styles.title, styles['sm-title'], 'font-bold')}>
              It's faster in the <br />
              <RateLogo aria-label='Rate' className='inline-block align-baseline' width='81' height='33' /> App
            </h3>
          </div>
          <div>
            <p className={cn(styles.text, styles['sm-text'])}>
              Use your phone for <span className='whitespace-nowrap'>easy uploads.</span>
            </p>
          </div>
        </div>
      </div>
      <div className={cn(styles.photo, 'relative w-full')} style={{ minHeight: '156px' }}>
        <div
          className='hidden sm:block absolute top-0 left-0 w-full h-full z-1 bg-no-repeat bg-cover bg-left-bottom'
          style={{ backgroundImage: `url(${holdingPhoneImage})` }}
        />
        <div
          className='sm:hidden absolute top-0 left-0 w-full h-full z-1 bg-no-repeat bg-cover bg-left-bottom'
          style={{ backgroundImage: `url(${holdingPhoneImageMobile})` }}
        />
        <div className='relative z-1 flex flex-col items-end pr-6 pt-11 sm:pt-6'>
          <div className='mb-2'>
            <Link href={link} target='_blank' onClick={() => triggerEvent({ id, action: 'appleStore', location })}>
              <img
                src={appleAppStoreImage}
                width='164'
                height='55'
                alt='Download on the Apple App Store'
                className={cn(styles['apple-store-image'], styles['sm-apple-store-image'])}
              />
            </Link>
          </div>
          <div>
            <Link href={link} target='_blank' onClick={() => triggerEvent({ id, action: 'googlePlay', location })}>
              <img
                src={googlePlayStoreImage}
                width='182'
                height='55'
                alt='Get it on Google Play'
                className={cn(styles['google-play-image'], styles['sm-google-play-image'])}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RateAppAd2 = ({ location }: { location: RateAppAdLocations }) => {
  const id = 'rateAppAd2';
  const link = useLink({ location });
  return (
    <div
      className={cn(styles['rate-app-ad-2'], 'relative rounded-xl overflow-hidden p-6 w-full')}
      style={{ backgroundColor: '#090909' }}
    >
      <div
        className='absolute top-0 left-0 w-full h-full z-1 bg-no-repeat bg-cover bg-right-top'
        style={{ backgroundImage: `url(${holdingCoffeeImage})` }}
      />
      <div className='relative z-1'>
        <div className='mb-2 lg:mb-3'>
          <h3 className={cn(styles.title, 'font-bold text-white')}>
            It's faster in <br />
            the <RateLogoWhite aria-label='Rate' className='inline-block align-baseline' width='62' height='25' /> App
          </h3>
        </div>
        <div className='mb-3 lg:mb-9'>
          <p className={cn(styles.text, 'text-white')}>
            Mortgages and loans<br />
            right from your phone.
          </p>
        </div>
        <div>
          <div className='mb-2'>
            <Link href={link} target='_blank' onClick={() => triggerEvent({ id, action: 'appleStore', location })}>
              <img
                src={appleAppStoreImage}
                width='164'
                height='55'
                alt='Download on the Apple App Store'
                className={styles['apple-store-image']}
              />
            </Link>
          </div>
          <div>
            <Link href={link} target='_blank' onClick={() => triggerEvent({ id, action: 'googlePlay', location })}>
              <img
                src={googlePlayStoreImage}
                width='182'
                height='55'
                alt='Get it on Google Play'
                className={styles['google-play-image']}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
