import { Fragment } from 'react';
import { useShownLo } from '../../hooks/useShownLo';
import { content } from '../../features/tenant/tenant';
import { useIsAuth } from '../../features/auth/authSlice';
import { formatPhoneNumber, unformatPhoneNumber } from '../../utils/phone';
import Link from '../ui/link/Link';
import Tel from '../ui/tel/Tel';
import ehlLogo from '../../images/ehl-logo.svg';
import eraLogo from '../../images/logo-era.png';
import betterHomesLogo from '../../images/logo-better-homes.png';
import cbLogo from '../../images/logo-cb.png';
import century21Logo from '../../images/logo-century-21.png';
import realtorLogo from '../../images/logo-realtorcom.svg';
import rateLogo from '../../images/logo-rate.svg';
import cn from 'classnames';
import useError from '../../hooks/useError';
import { LoanSupportFooter, WrapLoanSupportFooter } from '../loan/support/Support';
import { useShowHelpfulLinks } from '../../features/managedContent/managedContentSlice';
import { useRouteMatch } from 'react-router-dom';
import { useAppSelector } from '../../hooks/hooks';
import { RootState } from '../../store/types';
import { selectLoan } from '../../features/loans/loansSlice';
import { loanGuidPaths } from '../routes/paths';
import useEmbed from '../../hooks/useEmbed';
import Button from '../ui/button/Button';
import { mainSite } from '../../utils/grLinks';
import { selectGlobalAdtrk } from '../../features/app/appSlice';
import { selectCurrentLo } from '../../features/user/selectCurrentLo';
import { useLoanConfig } from '../../hooks/useLoanConfig';
import { Loan } from '../../features/loans/types';

const LoInfo = () => {
  const isAuthenticated = useIsAuth();
  const { isGlobalError } = useError();
  const lo = useShownLo();

  if (isGlobalError || !isAuthenticated || !lo) {
    return null;
  }

  return (
    <div className='mt-4'>
      <p className='OneLinkNoTx'>
        {lo.name || lo['role-name']}
        {lo.phone && (
          <>
            {' '}
            <Tel tel={unformatPhoneNumber(lo.phone)} label={formatPhoneNumber(lo.phone)} />
          </>
        )}
      </p>
      {(lo['nmls-id'] || (lo.licenses && !!lo.licenses.length)) && (
        <p>
          {lo['nmls-id'] && `NMLS ID: ${lo['nmls-id']}${!!lo.licenses?.length ? ';' : ''}`}
          {lo.licenses?.map((license, i) => {
            return (
              <Fragment key={i}>
                {`${i !== 0 ? ',' : ''} `}
                <span className='whitespace-nowrap'>{`${license['state']} - ${license['license-number']}`}</span>
              </Fragment>
            );
          })}
        </p>
      )}
    </div>
  );
};

const RDCFooter = ({ loan }: { loan: Loan }) => {
  const loanConfig = useLoanConfig(loan);

  if (!loanConfig.isRDC) {
    return null;
  }

  const RdcLogo = ({src, alt, height}: { src: string; alt: string; height: number }) => {
    return (
      <img
        src={src}
        alt={alt}
        height={height}
        style={{ height: `${height}px` }}
      />
    );
  };

  return (
    <div className='my-12 flex'>
      {/* desktop */}
      <div className='hidden sm:flex flex-row'>
        <div className='flex items-center pr-2 md:pr-4'>
          <RdcLogo src={rateLogo} alt='rate.com' height={20} />
        </div>
        <div className='flex items-center px-2 md:px-4'>
          <RdcLogo src={realtorLogo} alt='realtor.com' height={20} />
        </div>
        <div className='flex items-center px-2 md:px-4'>
          <RdcLogo src={eraLogo} alt='ERA' height={40} />
        </div>
        <div className='flex items-center px-2 md:px-4'>
          <RdcLogo src={betterHomesLogo} alt='Better Homes' height={40} />
        </div>
        <div className='flex items-center px-2 md:px-4'>
          <RdcLogo src={cbLogo} alt='CB' height={30} />
        </div>
        <div className='flex items-center pl-2 md:pl-4'>
          <RdcLogo src={century21Logo} alt='Century 21' height={12} />
        </div>
      </div>
      {/* mobile */}
      <div className='w-full flex flex-col sm:hidden'>
        <div className='flex flex-row justify-evenly'>
          <div className='w-1/2 flex items-center justify-center'>
            <RdcLogo src={rateLogo} alt='rate.com' height={20} />
          </div>
          <div className='w-1/2 flex items-center justify-center'>
            <RdcLogo src={realtorLogo} alt='realtor.com' height={20} />
          </div>
        </div>
        <div className='mt-4 flex flex-row justify-evenly'>
          <div className='w-1/2 flex items-center justify-center'>
            <RdcLogo src={eraLogo} alt='ERA' height={40} />
          </div>
          <div className='w-1/2 flex items-center justify-center'>
            <RdcLogo src={betterHomesLogo} alt='Better Homes' height={40} />
          </div>
        </div>
        <div className='mt-4 flex flex-row justify-evenly'>
          <div className='w-1/2 flex items-center justify-center'>
            <RdcLogo src={cbLogo} alt='CB' height={30} />
          </div>
          <div className='w-1/2 flex items-center justify-center'>
            <RdcLogo src={century21Logo} alt='Century 21' height={12} />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Application Footer component
 */
const Footer = () => {
  const routeMatch = useRouteMatch<{ guid: string | undefined }>({ path: loanGuidPaths, exact: true });
  const loanGuid = routeMatch?.params?.guid;
  const loan = useAppSelector((state: RootState) => loanGuid && selectLoan(state, loanGuid));
  const embed = useEmbed();
  const adtrk = useAppSelector(selectGlobalAdtrk);
  const lo = useAppSelector(selectCurrentLo);
  const { isGlobalError } = useError();
  const showLoanSupport = useShowHelpfulLinks() && !isGlobalError;
  const alternate = content.footer.alternateLayout;

  const footerLinksClassNames = 'my-4';
  const footerLinksUlClassNames = cn({ '-mx-2': !alternate }, { '-mx-3': alternate });
  const footerLinksLiClassNames = cn(
    'mb-1 border-r border-gray-25 inline last:mb-0 last:border-r-0',
    { 'px-2': !alternate },
    { 'px-3': alternate },
  );

  return showLoanSupport || !embed ? (
    <footer className={cn({ 'mt-18 mb-4': !embed }, { 'mt-8': embed }, 'lg:mt-18')}>
      <div
        className={cn('border-t border-gray-25 text-body text-sm', {
          'footer-alternate': alternate,
        })}
        style={{ lineHeight: '1.57' }}
      >
        {showLoanSupport && loan && (
          <WrapLoanSupportFooter>
            <LoanSupportFooter loan={loan} />
          </WrapLoanSupportFooter>
        )}
        {!embed && (
          <>
            {alternate && (
              <div className='my-4 mt-12'>
                <p>
                  <span>
                    Copyright &copy; {content.footer.copyrightStart && `${content.footer.copyrightStart}-`}
                    {new Date().getFullYear()} <span className='OneLinkNoTx'>{content.legalName || content.company}</span>. All rights reserved.
                  </span>
                </p>
              </div>
            )}

            {loan && <RDCFooter loan={loan} />}

            <nav className={cn(footerLinksClassNames, { 'mt-4': alternate }, { 'mt-8': !alternate })}>
              <ul className={footerLinksUlClassNames}>
                {content.footer.topLinks.map((link, i) => {
                  return (
                    <li key={i} className={footerLinksLiClassNames}>
                      <Button
                        buttonType='plain'
                        className={cn('text-sm', link.bold ? 'font-bold' : undefined)}
                        href={link.otherSite ? link.link : mainSite(link.link, { adtrk, loid: lo?.['emp-id'] })}
                        target='_blank'
                        rel='noopener'
                      >
                        {link.text}
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </nav>

            {(!alternate || content.footer.bottomLinks?.length) && <hr />}

            {!!content.footer.bottomLinks?.length && (
              <nav className={cn(footerLinksClassNames)}>
                <ul className={footerLinksUlClassNames}>
                  {content.footer.bottomLinks.map((link, i) => {
                    return (
                      <li key={i} className={footerLinksLiClassNames}>
                        <Button
                          buttonType='plain'
                          className={cn('text-sm', link.bold ? 'font-bold' : undefined)}
                          href={link.otherSite ? link.link : mainSite(link.link, { adtrk, loid: lo?.['emp-id'] })}
                          target='_blank'
                          rel='noopener'
                        >
                          {link.text}
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            )}

            {!!content.footer.translationDisclaimer && (
              <p className='font-normal'>
                {content.footer.translationDisclaimer}
              </p>
            )}

            <div className={cn('my-4', { 'mt-8': !alternate })}>
              <img
                src={ehlLogo}
                alt='Equal Housing Lender Badge'
                width='150'
                height='30'
                style={{ width: '150px', height: '30px' }}
              />
            </div>

            <div className='my-4 last:mb-0'>
              {!alternate && (
                <p>
                  <span>
                    Copyright &copy; {content.footer.copyrightStart && `${content.footer.copyrightStart}-`}
                    {new Date().getFullYear()} {content.legalName || content.company}.
                    <br className='md:hidden' /> All rights reserved.
                  </span>
                </p>
              )}

              {content.footer.licenses.map((license, i) => (
                <p className={cn({ 'font-normal mt-2': alternate && i === 0 })} key={i}>
                  {license.licenseText}
                  {license.delimiter || ' #'}
                  {license.licenseNumber}
                </p>
              ))}
              {alternate && (
                <p className='font-normal mt-2 OneLinkNoTx'>
                  <span>
                    {content.footer.address1}
                    {content.footer.address2 && `, `}
                    {content.footer.address2}
                  </span>
                </p>
              )}
              {!alternate && (
                <p className='OneLinkNoTx'>
                  <span>
                    {content.footer.address1}
                    {` `}
                    {content.footer.address2}
                    {` `}
                    <br className='md:hidden' />
                    <span className='hidden md:inline'>- </span>
                    <Tel tel={unformatPhoneNumber(content.footer.phone)} label={formatPhoneNumber(content.footer.phone)} />
                    {content.footer.phoneDisclaimer && ` ${content.footer.phoneDisclaimer}`}
                  </span>
                </p>
              )}
              {!!content.footer.alternateLegalBlurb?.length &&
                content.footer.alternateLegalBlurb?.map((stringOrBlurb, i) => {
                  return typeof stringOrBlurb === 'string' ? (
                    <p key={i} className={cn({ 'font-normal mt-2': i === 0 })}>
                      {stringOrBlurb}
                    </p>
                  ) : (
                    <p key={i} className='font-normal mt-2'>
                      {stringOrBlurb.map((stringOrLink, j) => {
                        return typeof stringOrLink === 'string' ? (
                          stringOrLink
                        ) : (
                          <Link key={j} className='bold' href={stringOrLink.link} target='_blank' rel='noopener'>
                            {stringOrLink.text}
                          </Link>
                        );
                      })}
                    </p>
                  );
                })}
              {!alternate && <p>{content.footer.legalBlurbText}</p>}
            </div>
            {!!content.footer.veryBottomLinks?.length && (
              <nav className={cn(footerLinksClassNames, 'last:mb-0')}>
                <ul className={footerLinksUlClassNames}>
                  {content.footer.veryBottomLinks.map((link, i) => {
                    return (
                      <li key={i} className={footerLinksLiClassNames}>
                        <Link className={link.bold && 'bold'} href={link.link} target='_blank' rel='noopener'>
                          {link.text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            )}
            <LoInfo />
          </>
        )}
      </div>
    </footer>
  ) : embed ? (
    <div className='mt-18' />
  ) : null;
};

export default Footer;
