import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RateAlert, RateAlertData, RateAlertState } from './types';
import api from '../api';
import { RootState } from '../../store/types';
import { log } from '../../utils/logger';
import axios from 'axios';

const namespace = 'rateAlert';

const initialData: RateAlertData = {
  rateAlert: undefined,
  hasData: false,
  fetching: false,
  error: false,
};

const initialState: RateAlertState = {
  byLoanGuid: {},
};

export const fetchRateAlert = createAsyncThunk<RateAlert, string>(
  `${namespace}/fetchRateAlert`,
  async loanGuid => {
    try {
      const resp = await api.getRateAlert(loanGuid);
      return resp.data;
    } catch (error) {
      log({
        level: 'error',
        message: `fetchRateAlert error - ${error}`,
        isAxiosError: axios.isAxiosError(error),
      });
      throw error;
    }
  },
  {
    condition: (loanGuid, { getState }) => {
      const fetching = selectRateAlertFetching(getState() as RootState, loanGuid)
      return !fetching;
    },
  },
);

export const rateAlertSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchRateAlert.pending, (state, action) => {
        const loanGuid = action.meta.arg;
        state.byLoanGuid[loanGuid] = {
          ...initialData,
          ...state.byLoanGuid[loanGuid],
          fetching: true,
          error: false,
        };
      })
      .addCase(fetchRateAlert.fulfilled, (state, action) => {
        const payload = action.payload;
        const loanGuid = action.meta.arg;
        state.byLoanGuid[loanGuid] = {
          ...state.byLoanGuid[loanGuid],
          hasData: true,
          rateAlert: payload,
          fetching: false,
        };
      })
      .addCase(fetchRateAlert.rejected, (state, action) => {
        const loanGuid = action.meta.arg;
        state.byLoanGuid[loanGuid] = {
          ...state.byLoanGuid[loanGuid],
          fetching: false,
          error: true,
        };
      })
  },
});

const selectRateAlertState = (state: RootState) => state.rateAlert;

export const selectRateAlertFetching = (state: RootState, loanGuid: string) => !!selectRateAlertState(state).byLoanGuid[loanGuid]?.fetching;
export const selectRateAlertHasData = (state: RootState, loanGuid: string) => !!selectRateAlertState(state).byLoanGuid[loanGuid]?.hasData;
export const selectRateAlertError = (state: RootState, loanGuid: string) => !!selectRateAlertState(state).byLoanGuid[loanGuid]?.error;
export const selectRateAlert = (state: RootState, loanGuid: string) => selectRateAlertState(state).byLoanGuid[loanGuid]?.rateAlert;

export default rateAlertSlice.reducer;
