import { Servicer, ServicingDetails, ServicingLoan } from '../../../features/servicing/types';
import { ServicingConfig } from '../../../hooks/useServicingConfig';
import { usdAmount } from '../../../utils/amount';
import { formatDateWithoutTimezone, FORMAT_SHORT } from '../../../utils/date';
import { formatPercent } from '../../../utils/percent';
import { formatPhoneNumber } from '../../../utils/phone';
import Button from '../../ui/button/Button';
import Card from '../../ui/card/Card';
import { IDataItem } from '../../ui/datalist/dataItem/IDataItem';
import Icon from '../../ui/icon/Icon';
import { iIconName } from '../../ui/icon/IIcon';
import { DetailsCardContent } from '../detailsCard/DetailsCard';
import LoanNumberTooltip from '../detailsCard/LoanNumberTooltip';

export const ServicingStatus = ({ icon, title, text }: { icon?: iIconName, title?: string, text?: string }) => {
  return (
    <>
      <div className='flex items-center mb-4 text-2xl'>
        {icon && <Icon name={icon} className='text-critical mr-5 align-middle' />}
        <h1 className='header-small'>{title}</h1>
      </div>
      {text &&
        <p>
          {text}
        </p>
      }
    </>
  );
};

const ServicingTransferInfo = ({ newServicer, servicing }: { newServicer: Servicer, servicing: ServicingDetails}) => {
  const data: IDataItem[] = [
    {
      value: servicing.transferDate ? formatDateWithoutTimezone(servicing.transferDate, FORMAT_SHORT) : 'Not available',
      label: 'Transfer effective date',
      labelClassName: 'mb-0',
    },
    {
      value: newServicer.name,
      label: 'New servicer',
      labelClassName: 'mb-0',
    },
    {
      value: newServicer.phone ? formatPhoneNumber(newServicer.phone) : '',
      label: 'New servicer phone',
      labelClassName: 'mb-0',
    },
  ];

  return (
    <DetailsCardContent className='gap-y-4' details={data} />
  );
};

const LoanSoldInfo = ({ newServicer }: { newServicer: Servicer }) => {
  const data: IDataItem[] = [
    {
      value: newServicer.name,
      label: 'Agency name',
      labelClassName: 'mb-0',
    },
    {
      value: newServicer.phone ? formatPhoneNumber(newServicer.phone) : '',
      label: 'Agency phone',
      labelClassName: 'mb-0',
    },
  ];

  return (
    <DetailsCardContent className='gap-y-4' details={data} />
  );
};

const ServicingDetailsComponent = ({ loan, isHELOC }: { loan: ServicingLoan; isHELOC?: boolean }) => {
  const data: IDataItem[] = [
    {
      value: loan.loanNumber,
      label: 'Loan number',
      tooltip: LoanNumberTooltip,
      labelClassName: 'mb-0',
    },
    {
      value: loan.loanTermString || 'Not available',
      label: 'Loan term',
      labelClassName: 'mb-0',
    },
    {
      value: usdAmount(loan.loanAmount),
      label: 'Loan amount',
      labelClassName: 'mb-0',
    },
    {
      value: isHELOC ? formatPercent(loan.interestRate * 100, 3) : formatPercent(loan.interestRate, 3),
      label: 'Interest rate',
      labelClassName: 'mb-0',
    },
    {
      value: loan.firstPaymentDue ? formatDateWithoutTimezone(loan.firstPaymentDue, FORMAT_SHORT) : 'Not available',
      label: 'First payment due',
      labelClassName: 'mb-0',
    },
    {
      value: usdAmount(loan.firstPaymentAmount),
      label: 'First payment amount',
      labelClassName: 'mb-0',
    }
  ];

  return (
    <DetailsCardContent className='gap-y-4' details={data} />
  );
};

const ServicingDetailsCard = ({ config }: { config: ServicingConfig }) => {
  const { servicing, servicingLoan, servicingTitleIcon, newServicer } = config;

  const onTransferFAQ = () => {
    const faqsElement = document.getElementById('servicing-transfer-faqs');
    faqsElement?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Card>
      <ServicingStatus icon={servicingTitleIcon} title={servicing.title} text={servicing.content} />
      {[3, 4, 5, 6].includes(servicing.experienceInd) &&
        <Button buttonType='tertiary' className='mb-4' text='Common question about loan transfers' onClick={onTransferFAQ} />
      }
      {newServicer && [3, 4, 5, 6].includes(servicing.experienceInd) &&
        <ServicingTransferInfo newServicer={newServicer} servicing={servicing} />
      }
      {newServicer && [8].includes(servicing.experienceInd) &&
        <LoanSoldInfo newServicer={newServicer} />
      }
      <hr className='my-6' />
      <ServicingDetailsComponent loan={servicingLoan} isHELOC={config.isHELOC} />
    </Card>
  );
};

export default ServicingDetailsCard;
