import cn from 'classnames';
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { sendEvent } from '../../features/analytics/sendEvent';
import { unauthenticate } from '../../features/auth/authSlice';
import { isALoanFunded } from '../../features/loans/loansSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import useCobrowse from '../../hooks/useCobrowse';
import useError from '../../hooks/useError';
import { useFeatureEnabled } from '../../hooks/useFeatureEnabled';
import useStartAnApplicationLink from '../../hooks/useStartAnApplicationLink';
import { isDev, isLocalDev } from '../../utils/env';
import { log } from '../../utils/logger';
import { lcfirst } from '../../utils/str';
import { cobrowseSubject } from '../cobrowse/Cobrowse';
import { NAMES, PATHS, TITLES } from '../routes/paths';
import Button from '../ui/button/Button';
import { ButtonProps } from '../ui/button/IButton';
import { getConfigFromStore } from '../../features/config/getConfigFromStore';

type SecondaryNavigationLinkId = 'secondaryNavigationLinks' | 'headerDropdown';

type NavigationButtonProps = ButtonProps & {
  isCurrent: boolean;
};

const NavigationButton = memo(({ buttonType, text, iconName, isCurrent, ...restProps }: NavigationButtonProps) => {
  // aria
  const ariaCurrent: any = {};
  if (isCurrent) ariaCurrent['aria-current'] = 'page';

  return (
    <Button
      buttonType={buttonType}
      text={text}
      className='w-fit-content'
      {...(iconName && {
        iconName,
        iconPlacement: 'left',
      })}
      {...ariaCurrent}
      {...restProps}
    />
  );
});

export type NavigationLinkProps = ButtonProps & {
  id: string;
  text: string;
  disabled?: boolean;
  disableIsCurrent?: boolean;
};

export const NavigationLink = ({ to, disableIsCurrent, ...restProps }: NavigationLinkProps) => {
  const { pathname } = useLocation();

  // Is link current?
  const isCurrent = pathname === to;

  // Is link ancestor current?
  const isLoanCenterAncestor = to === PATHS.loanCenter && pathname.indexOf('/loan/') === 0;
  const isStyleguideAncestor = to === PATHS.styleguide && pathname.indexOf(PATHS.styleguide) === 0;
  const isCurrentAncestor = !isCurrent && (isLoanCenterAncestor || isStyleguideAncestor);

  return (
    <li
      role='menuitem'
      className={cn('rounded-xl', { 'nav-current': isCurrent }, { 'nav-current-ancestor': isCurrentAncestor })}
    >
      <NavigationButton {...restProps} to={to} isCurrent={!disableIsCurrent && isCurrent} />
    </li>
  );
};

export const useSecondaryNavigationLinks = (id: SecondaryNavigationLinkId) => {
  const dispatch = useAppDispatch();
  const { link: startAnApplicationLink, lo: currentLo } = useStartAnApplicationLink();
  const { enabled: cobrowseEnabled, inSession: cobrowseInSession } = useCobrowse();
  const { showingError } = useError();

  const handleLogout = () => {
    dispatch(unauthenticate());
  };

  const onStartApplicationClick = () => {
    sendEvent(`startAnApplication: ${id}`);
    log({
      level: 'info',
      message: `User clicked \`startAnApplication\` from ${id} lo: ${
        currentLo &&
        JSON.stringify({
          name: currentLo.name,
          email: currentLo.email,
          id: currentLo.id,
          empId: currentLo['emp-id'],
        })
      }`,
    });
  };

  const onStartCobrowse = () => {
    sendEvent(`startCobrowse: ${id}`);
    log({ level: 'info', message: `User clicked \`startCobrowse\` from ${id}` });
    cobrowseSubject.next(true);
  };

  const navLinks: NavigationLinkProps[] = [];
  if (!showingError) {
    navLinks.push(
      {
        buttonType: 'subnav',
        size: 'medium',
        to: PATHS.profile,
        id: NAMES.profile,
        text: TITLES.profile,
        title: `Visit ${lcfirst(TITLES.profile)}`,
        disableIsCurrent: true,
      },
      {
        buttonType: 'subnav',
        size: 'medium',
        href: startAnApplicationLink,
        onClick: onStartApplicationClick,
        target: '_blank',
        rel: 'noopener',
        id: 'start-application',
        text: 'Start an application',
        title: 'Start an application',
      },
    );
  }

  if (cobrowseEnabled && !cobrowseInSession) {
    navLinks.push({
      buttonType: 'subnav',
      size: 'medium',
      onClick: onStartCobrowse,
      id: 'cobrowse',
      text: 'Share screen',
      title: 'Share screen',
    });
  }

  navLinks.push({
    buttonType: 'subnav',
    size: 'medium',
    onClick: handleLogout,
    id: NAMES.logout,
    text: TITLES.logout,
    title: TITLES.logout,
  });

  return navLinks;
};

export const SecondaryNavigationLinks = ({ id, className }: { id?: SecondaryNavigationLinkId; className?: string }) => {
  id = id || 'secondaryNavigationLinks';
  const navLinks = useSecondaryNavigationLinks(id);

  return (
    <ul className={className}>
      {navLinks.map(props => {
        return <NavigationLink key={props.id} {...props}></NavigationLink>;
      })}
    </ul>
  );
};

export const MainNavigationLinks = ({ className }: { className?: string }) => {
  const config = getConfigFromStore();
  const canShowHomeValue = useAppSelector(isALoanFunded);
  const isHomeValueEnabled =
    (useFeatureEnabled('home-value') && canShowHomeValue) || (isLocalDev && process.env.REACT_APP_ENABLE_HOME_VAL);
  const isHomeSearchEnabled = useFeatureEnabled('home-search') && config?.links['home-search'];
  const isInsuranceLandingEnabled = useFeatureEnabled('insurance-landing');
  const isStyleguideEnabled = isLocalDev || isDev;

  return (
    <ul className={className}>
      <NavigationLink
        buttonType='nav'
        to={PATHS.loanCenter}
        id={NAMES.loanCenter}
        text={TITLES.loanCenter}
        title={`Visit ${lcfirst(TITLES.loanCenter)}`}
        iconName='doc-check'
      />
      {isHomeValueEnabled && (
        <NavigationLink
          buttonType='nav'
          to={PATHS.homeValue}
          id={NAMES.homeValue}
          text={TITLES.homeValue}
          title={`Visit ${lcfirst(TITLES.homeValue)}`}
          iconName='calculator'
        />
      )}
      {isHomeSearchEnabled && (
        <NavigationLink
          buttonType='nav'
          to={PATHS.homeSearch}
          id={NAMES.homeSearch}
          text={TITLES.homeSearch}
          title={`Visit ${lcfirst(TITLES.homeSearch)}`}
          iconName='search'
        />
      )}
      {isInsuranceLandingEnabled && (
        <NavigationLink
          buttonType='nav'
          to={PATHS.insurance}
          id={NAMES.insurance}
          text={TITLES.insurance}
          title={`Visit ${lcfirst(TITLES.insurance)}`}
          iconName='shield-check'
        />
      )}
      {isStyleguideEnabled && (
        <NavigationLink
          buttonType='nav'
          to={PATHS.styleguide}
          id={NAMES.styleguide}
          text={TITLES.styleguide}
          title={`Visit ${lcfirst(TITLES.styleguide)}`}
          iconName='file'
        />
      )}
    </ul>
  );
};
