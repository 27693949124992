import Link from '../ui/link/Link';
import { content } from '../../features/tenant/tenant';
import myAccountLogo from '../../images/myaccount-logo.svg';
import themeConfig from '../../features/tenant/tenant';
import cn from 'classnames';
import useError from '../../hooks/useError';
import useEmbed from '../../hooks/useEmbed';

const HeaderLogo = ({ className, showSecondaryLogo = true }: { className?: string; showSecondaryLogo?: boolean }) => {
  const embed = useEmbed();
  const { showingError } = useError();
  const { mobileLogo, logoMaxHeight, logoMaxWidth, logoWidth } = themeConfig.content.header;

  return (
    <div className={cn('header-logo', className)}>
      <Link
        className='flex items-center a-reset font-bold'
        {...(!embed && !showingError && { to: '/' })}
        {...(!embed && showingError && { href: '/' })}
        rel='home'
        title='Return to home'
        aria-label='Return to home'
      >
        {mobileLogo && (
          <img
            className='lg:hidden'
            src='/images/brand-logo-mobile.svg'
            alt={`${content.company} Logo`}
            style={{ maxHeight: logoMaxHeight }}
            width='48'
            height='32'
          />
        )}
        <img
          className={mobileLogo ? 'hidden lg:inline ml-4' : ''}
          src='/images/brand-logo.svg'
          alt={`${content.company} Logo`}
          style={{ maxHeight: logoMaxHeight, maxWidth: logoMaxWidth }}
          width={logoWidth}
        />
        {/* For mobile we always show the secondary logo
            but for desktop, sometimes we show it and sometimes we don't
        */}
        <img
          className={cn('inline ml-2', { 'lg:hidden': !showSecondaryLogo })}
          src={myAccountLogo}
          alt='MyAccount'
          width='72'
          style={{ position: 'relative', top: '-1px' }}
        />
      </Link>
    </div>
  );
};

export default HeaderLogo;
