import { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { RequestError } from '../../interfaces/IRequest';
import useHashToken from '../../hooks/useHashToken';
import api from '../../features/api';
import { log } from '../../utils/logger';
import { isEmail } from '../ui/form/validations';
import CreatePassword from './CreatePassword';
import { LockedResultComponent, SuccessResultComponent, TokenInvalidComponent } from './RegistrationLandingComponents';
import { EmailRegistrationForm, EmailSsnRegistrationForm } from './RegistrationForm';
import './Registration.scss';
import { Skeleton } from '../ui/skeleton/Skeleton';
import useSearchParams from '../../hooks/useSearchParams';
import { useAppSelector } from '../../hooks/hooks';
import { selectAppLanguage } from '../../features/app/appSlice';
import { sendEvent } from '../../features/analytics/sendEvent';
import { FormGroupElement } from '../ui/form/IFormGroup';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { TITLES } from '../routes/paths';

enum RegistrationStatus {
  Registered = 'registered',
  NotRegistered = 'not-registered'
}

enum HttpStatus {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  Conflict = 409,
  ServerError = 500
}

type RegistrationStatusResponseBody = {
  result: RegistrationStatus;
};

type RequestTokenErrorResponseBody = {
  remaining: number
};

const initRegistrationState = {
  isUserUnauthorized: false,
  canUserRegisterWithEmail: false,
  formError: '',
  isAccountLocked: false,
  isEmailSent: false,
  isSubmitting: false,
  email: '',
  emailError: '',
  ssn: '',
  ssnError: '',
  resendStatus: 'not-sent',
};

const UNEXPECTED_ERROR = 'An unexpected error has occurred, please try again.';

export const InitLoadingSkeleton = () => {
  return (
    <div className='flex flex-col w-full lg:w-72'>
      <Skeleton className='mb-4' style={{ height: '60px' }} />
      <Skeleton className='mb-4' style={{ height: '60px' }} />
      <Skeleton className='mb-4' style={{ height: '60px' }} />
    </div>
  );
};

const Registration = () => {
  const { location, replace } = useHistory<{ token?: string, email?: string }>();
  const token = useHashToken();
  const email = useSearchParams().get('email');
  const [checkingRegistrationStatus, setCheckingRegistrationStatus] = useState(false);
  const [tokenExpiredReason, setTokenExpiredReason] = useState(undefined);
  const [registrationState, setRegistrationState] = useState(initRegistrationState);
  const appLanguage = useAppSelector(selectAppLanguage);
  useDocumentTitle(TITLES.registration);

  useEffect(() => {
    const checkToken = async (token: string) => {
      if (!location.state) {
        replace(window.location.pathname, { token, email: undefined });
      }
      setCheckingRegistrationStatus(true);
      try {
        const resp = await api.getRegistrationStatus({ token });
        const result = resp?.data?.result;
        if (result === 'registered') {
          const email = (resp?.data?.email || '').toLowerCase();
          log({
            level: 'info',
            message: `Registration: Email from token is already registered ${JSON.stringify({
              token,
              email,
            })}`,
          });
          sendEvent(`userRegistration: emailRegisteredFromToken`, { token, email });
          window.location.replace(`/okta/login?email=${email}`);
        } else if (result === 'expired' || result === 'timed-out') {
          setTokenExpiredReason(result);
          setCheckingRegistrationStatus(false);
          log({
            level: 'info',
            message: `Registration: Create password screen token is expired ${JSON.stringify({
              token,
              reason: result,
            })}`,
          });
          sendEvent(`userRegistration: tokenExpired`, { token, reason: result });
        } else { // if result === 'not-registered'
          log({
            level: 'info',
            message: `Registration: Create password screen loaded ${JSON.stringify({
              token,
            })}`,
          });
          sendEvent(`userRegistration: passwordScreenLoaded`, { token });
          setCheckingRegistrationStatus(false);
        }
      } catch (error) {
        // token is not valid - clear registration for registration form
        clearRegistration();
        log({
          level: 'error',
          message: `Registration: Create password screen error ${JSON.stringify({
            token,
            error,
          })}`,
        });
        sendEvent(`userRegistration: tokenError`, { token });
      }
    };

    const checkEmail = async (email: string) => {
      if (!location.state) {
        replace(window.location.pathname, { token: undefined, email });
      }
      setCheckingRegistrationStatus(true);
      try {
        const resp = await api.getRegistrationStatus({ email });
        const result = resp?.data?.result;
        if (result === 'registered') {
          log({ level: 'info', message: `Registration: Email already registered: ${email}` });
          sendEvent(`userRegistration: emailRegistered`, { email });
          window.location.replace(`/okta/login?email=${email}`);
        } else {
          const validEmail = isEmail(email);
          setRegistrationState((state) => ({ ...state, email, emailError: validEmail ? '' : state.emailError }));
          setCheckingRegistrationStatus(false);
        }
      } catch (error) {
        clearRegistration();
        log({
          level: 'error',
          message: `Registration: checkEmail failed ${JSON.stringify({
            email,
            error,
          })}`,
        });
      }
    };

    if (token) {
      checkToken(token);
    } else if (email) {
      checkEmail(email);
    }
  }, [token, email]); // eslint-disable-line react-hooks/exhaustive-deps

  // submit email
  const handleSubmitEmail = async (formEvent: FormEvent): Promise<void> => {
    formEvent.preventDefault();
    try {
      setRegistrationState((state) => ({ ...state, isSubmitting: true }));
      const { result }: RegistrationStatusResponseBody =
        (await api.getRegistrationStatus({ email: registrationState.email }))?.data;

      switch (result) {
        case RegistrationStatus.Registered:
          // TODO: replace with Okta login (not the best UX;
          // consider displaying toast with redirect in setTimeout?)
          log({ level: 'info', message: `Registration: Email already registered: ${registrationState.email}` });
          sendEvent(`userRegistration: emailRegistered`, { email: registrationState.email });
          return window.location.replace(`/okta/login?email=${registrationState.email}`);
        case RegistrationStatus.NotRegistered:
          log({ level: 'info', message: `Registration: User can register. Asking for last4SSN. ${registrationState.email}` });
          sendEvent(`userRegistration: emailCanRegister`, { email: registrationState.email });
          return setRegistrationState((state) => ({
            ...state,
            canUserRegisterWithEmail: true
          }));
        default:
          log({ level: 'error', message: `Registration: Unhandled registration status for: ${registrationState.email}` });
          sendEvent(`userRegistration: unhandledRegistration`, { email: registrationState.email });
      }
    } catch (err) {
      const error = err as AxiosError<RequestError>;
      if (axios.isAxiosError(error)) {
        log({
          level: 'error',
          message: `Registration: Unknown server error while requesting registration status ${JSON.stringify({
            email: registrationState.email,
            error: error.response?.data,
          })}`,
        });
        sendEvent(`userRegistration: unexpectedError`, { email: registrationState.email });
        return setRegistrationState((state) => ({ ...state, formError: UNEXPECTED_ERROR }));
      } else {
        log({
          level: 'error',
          message: `Registration: Unhandled error while requesting registration status ${JSON.stringify({
            email: registrationState.email,
            error,
          })}`,
        });
        sendEvent(`userRegistration: unexpectedError`, { email: registrationState.email });
        return setRegistrationState((state) => ({ ...state, formError: UNEXPECTED_ERROR }));
      }
    } finally {
      setRegistrationState((state) => ({ ...state, isSubmitting: false }));
    }
  };

  const handleUnexpectedEmailAndSsnError = (error: any) => {
    log({
      level: 'error',
      message: `Registration: Unexpected error while submitting SSN ${JSON.stringify({
        email: registrationState.email,
        error,
      })}`,
    });
    sendEvent(`userRegistration: unexpectedErrorSNN`, { email: registrationState.email });
    return setRegistrationState((state) => ({ ...state, formError: UNEXPECTED_ERROR }));
  }

  // submit email and ssn
  const handleSubmitEmailAndSsn = async (formEvent: FormEvent): Promise<void> => {
    formEvent.preventDefault();
    log({ level: 'info', message: `Registration: Attempting to create activation link for email: ${registrationState.email}` });
    try {
      setRegistrationState((state) => ({ ...state, isSubmitting: true, ssnError: '' }));
      await api.postRegistrationRequest(registrationState.email, registrationState.ssn, appLanguage);
      log({ level: 'info', message: `Registration: Email successfully sent for email: ${registrationState.email}` });
      sendEvent(`userRegistration: emailSent`, { email: registrationState.email});
      setRegistrationState((state) => ({ ...state, isEmailSent: true }));
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError<RequestTokenErrorResponseBody>;
        switch ((error.response?.status ?? HttpStatus.ServerError) as HttpStatus) {
          case HttpStatus.Unauthorized:
            const { remaining } =
              (error.response?.data ?? { remaining: 0 });
            log({
              level: 'info',
              message: `Registration: Email and SSN registration is unauthorized ${JSON.stringify({
                email: registrationState.email,
                error: error.response?.data
              })}`,
            });
            sendEvent(`userRegistration: unauthorizedSSN`, { email: registrationState.email });
            return setRegistrationState((state) => ({
              ...state,
              formError: `We were unable to verify your identity${
                remaining > 2
                  ? '; please try again.'
                  : remaining > 0
                  ? `; ${remaining} attempt${ remaining !== 1 ? 's' : '' } remaining.`
                  : '.' // should never reach this.
              }`
            }));
          case HttpStatus.Conflict:
            log({ level: 'error', message: `Registration: Email and SSN registration conflict for email: ${registrationState.email}` });
            sendEvent(`userRegistration: conflictSSN`, { email: registrationState.email });
            return setRegistrationState((state) => ({
              ...state,
              formError: 'Please log in if you have previously registered.'
            }));
          case HttpStatus.Forbidden:
            log({ level: 'error', message: `Registration: Email and SSN registration is forbidden for email: ${registrationState.email}` });
            sendEvent(`userRegistration: forbiddenSSN`, { email: registrationState.email });
            return setRegistrationState((state) => ({ ...state, isAccountLocked: true }));
          case HttpStatus.ServerError:
          default:
            handleUnexpectedEmailAndSsnError(error);
        }
      } else {
        handleUnexpectedEmailAndSsnError(err);
      }
    } finally {
      setRegistrationState((state) => ({ ...state, isSubmitting: false }));
    }
  };

  // email blur
  const handleValidateEmail = () => {
    const validEmail = isEmail(registrationState.email);
    setRegistrationState((state) => ({ ...state, emailError: validEmail ? '' : 'Enter a valid email address' }));
  }

  // ssn blur
  const handleValidateSSN = () => {
    registrationState.ssn.length !== 4 && setRegistrationState((state) => ({ ...state, ssnError: 'Enter a valid number of digits.' }));
  }

  // email on change
  const handleEmailOnChange = (changeEvent: ChangeEvent<FormGroupElement>) => {
    const { value } = changeEvent.target;
    const validEmail = isEmail(value);
    setRegistrationState((state) => ({ ...state, email: value, emailError: validEmail ? '' : state.emailError }));
  };

  // ssn on change (only allow positive integers)
  const handleSsnMaxLength = (changeEvent: ChangeEvent<FormGroupElement>) => {
    const value = changeEvent.target.value.replaceAll(/[^0-9]*/g, '');
    const validSsn = value.length === 4;
    setRegistrationState((state) => ({ ...state, ssn: value, ssnError: validSsn ? '' : state.ssnError }));
  };

  // success Result callback
  const handleResendEmail = async () => {
    log({ level: 'info', message: 'Registration: Resending registration email' });
    try {
      if (registrationState.resendStatus === 'not-sent' || registrationState.resendStatus === 'error') {
        setRegistrationState(state => ({ ...state, resendStatus: 'processing' }));
        await api.postRegistrationRequest(registrationState.email, registrationState.ssn, appLanguage);
        setRegistrationState(state => ({ ...state, resendStatus: 'success' }));
      }
    } catch (error) {
      setRegistrationState(state => ({ ...state, resendStatus: 'error' }));
      log({ level: 'error', message: `Registration: Error when resending registration for email ${registrationState.email}` });
    }
  };

  // CreatePassword submit handler
  const handleSubmitPassword = async (password: string) => {
    log({
      level: 'info',
      message: `Registration: Attempting account creation ${JSON.stringify({
        token,
      })}`,
    });
    const { email } = await api.postRegistrationComplete(token, password, appLanguage);
    log({
      level: 'info',
      message: `Registration: Successfully registered user ${JSON.stringify({
        token,
        email,
      })}`,
    });
    sendEvent(`userRegistration: registrationComplete`, { token, email });
    setCheckingRegistrationStatus(false);
    window.location.replace(`/okta/login?email=${email}`)
  };

  const handlePasswordError = (error: any) => {
    if (axios.isAxiosError(error)) {
      switch (error.response?.status as HttpStatus) {
        case HttpStatus.ServerError:
          log({
            level: 'error',
            message: `Registration: Unexpected password error ${JSON.stringify({
              token,
              reason: '500 Unexpected Error',
              error: error.response?.data
            })}`,
          });
          sendEvent(`userRegistration: passwordError`, { token, reason: '500 Unexpected Error' });
          break;
        case HttpStatus.Unauthorized:
          handlePassword401Error(error);
          break;
        case HttpStatus.BadRequest:
          log({
            level: 'info',
            message: `Registration: Okta password error ${JSON.stringify({
              token,
              reason: '400 Okta Error',
              error: error.response?.data
            })}`,
          });
          sendEvent(`userRegistration: passwordError`, { token, reason: '400 Okta Error' });
          break;
        case HttpStatus.Conflict:
          const { email: registeredEmail } = error.response?.data as { email: string } || {};
          log({ level: 'info', message: `Registration: Email already registered: ${registeredEmail}` });
          sendEvent(`userRegistration: emailRegistered`, { email: registeredEmail });
          window.location.replace(`/okta/login${registeredEmail ? '?email=' + registeredEmail : ''}`);
          break;
        default:
          log({
            level: 'error',
            message: `Registration: Unknown password error ${JSON.stringify({
              token,
              reason: 'Unknown Error',
              error: error.response?.data,
            })}`,
          });
          sendEvent(`userRegistration: passwordError`, { token, reason: 'Unknown Error' });
          break;
      }
    } else {
      log({
        level: 'error',
        message: `Registration: Unexpected password error ${JSON.stringify({
          token,
          reason: 'Unexpected Error',
          error,
        })}`,
      });
      sendEvent(`userRegistration: passwordError`, { token, reason: 'Unexpected Error' });
    }
  }

  // CreatePassword 401 handler
  const handlePassword401Error = (error: any) => {
    replace(window.location.pathname, {
      ...location.state,
      token: undefined,
      email: undefined,
    });
    log({
      level: 'info',
      message: `Registration: Unauthorized password error ${JSON.stringify({
        token,
        reason: '401 Unauthorized Error',
        error,
      })}`,
    });
    sendEvent(`userRegistration: passwordError`, { token, reason: '401 Unauthorized Error' });
    setRegistrationState((state) => ({ ...state, isUserUnauthorized: true }));
  };

  const clearRegistration = () => {
    replace(window.location.pathname, {
      ...location.state,
      token: undefined,
      email: undefined,
    });
    setCheckingRegistrationStatus(false);
    setTokenExpiredReason(undefined);
  };

  const formProps = {
    isAccountLocked: registrationState.isAccountLocked,
    isSubmitting: registrationState.isSubmitting,
    formError: registrationState.formError,
    email: registrationState.email || location.state?.email || '',
    emailError: registrationState.emailError,
    handleEmailOnChange: handleEmailOnChange,
    handleValidateEmail: handleValidateEmail,
    handleSubmitEmail: handleSubmitEmail,
    canUserRegisterWithEmail: registrationState.canUserRegisterWithEmail,
    ssn: registrationState.ssn,
    ssnError: registrationState.ssnError,
    handleSSNMaxLength: handleSsnMaxLength,
    handleValidateSSN: handleValidateSSN,
    handleSubmitEmailAndSsn: handleSubmitEmailAndSsn,
  };

  return (
    <div className='registration__container mx-auto mt-5 lg:mt-4'>
      {
        checkingRegistrationStatus ? <InitLoadingSkeleton /> :
        tokenExpiredReason ? <TokenInvalidComponent reason={tokenExpiredReason} clearRegistration={clearRegistration} /> :
        registrationState.isUserUnauthorized ? <LockedResultComponent /> :
        registrationState.isEmailSent ? <SuccessResultComponent handleResendEmail={handleResendEmail} resendStatus={registrationState.resendStatus} /> :
        token ? <CreatePassword handleSubmitPassword={handleSubmitPassword} handlePasswordError={handlePasswordError} /> :
        registrationState.canUserRegisterWithEmail ? <EmailSsnRegistrationForm {...formProps} /> :
        <EmailRegistrationForm {...formProps} />
      }
    </div>
  );
};

export default Registration;
