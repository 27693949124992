import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { ConsentCustomMeta, iConsentType } from '../../../features/tasks/types';
import { ConsentTaskBodyProps, ConsentTaskTitleProps } from './IConsent';
import { sendConsent } from '../../../features/tasks/actions';
import { setModalContent, openModal, closeModal } from '../tasklist/TaskModal';
import { ModalConsentDeclineConfirmation, ModalConsentSuccess } from './ConsentModal';
import ModalDisclaimerContent from '../../ui/modal/ModalDisclaimerContent';
import ModalLoading from '../../ui/modal/ModalLoading';
import TaskTitleBadge from '../task/TaskTitleBadge';
import Button from '../../ui/button/Button';
import { simon } from '../../../utils/grLinks';
import ErrorModalContent from '../../ui/modal/ErrorModalContent';
import { useDefaultSupport } from '../../../hooks/useDefaultSupport';
import { selectAppLanguage } from '../../../features/app/appSlice';
import { useMultilingual } from '../../../hooks/useMultilingual';
import '../task/Task.scss';
import { log } from '../../../utils/logger';
import { setDataLayer } from '../../../features/analytics/setDataLayer';

export const CONSENT_TYPES = ['eConsent', 'twnConsent'] as const;

const CONTENT = {
  eConsent: {
    title: 'Would you like to be able to sign your documents online throughout the process?',
    subtitle: "You'll reap the benefits of a faster, more secure mortgage experience by saying yes!",
    showCurrentDate: false,
  },
  twnConsent: {
    title: 'Verify your income the easy way!',
    subtitle: 'Providing this information allows us to continue processing your loan.',
    showCurrentDate: true,
  },
};

const getContent = <U extends keyof typeof CONTENT>(consentType: U) => CONTENT[consentType];

const getDisclaimer = (customTaskMeta: ConsentCustomMeta, showMultilingual: boolean, language: string | null) => {
  if (showMultilingual && language && customTaskMeta['multilingual-html']?.[language]) {
    return customTaskMeta['multilingual-html'][language];
  }
  return customTaskMeta['task-consent-html'];
};

const useLinkConfig = (consentType: iConsentType, language: string | null) => {
  let link;
  let linkTitle;

  if (consentType === 'eConsent') {
    link = simon(`v1/consent/doc${language ? '?language=' + language : ''}`);
    linkTitle = 'eConsent pdf download';
  }

  return {
    link,
    linkTitle,
  };
};

export const ConsentTaskBody = (props: ConsentTaskBodyProps) => {
  const {
    task: { 'task-completed': completed, 'task-type': taskType, 'custom-task-meta': customTaskMeta, 'task-id': taskId },
    loanGuid,
  } = props;
  const { 'consent-type': consentType } = customTaskMeta;
  const dispatch = useAppDispatch();
  const appLanguage = useAppSelector(selectAppLanguage);

  const buttonText = completed ? 'Edit' : 'Review';
  const link = useLinkConfig(taskType, appLanguage);
  const content = {
    ...getContent(taskType),
    disclaimer: getDisclaimer(customTaskMeta, useMultilingual(), appLanguage),
    ...(link && { link }),
  };

  const handleButtonClick = () => {
    log({ loanGuid, taskId, message: `Consent: ${taskType} task modal opened` });
    openModal(taskId, modalContentDefault, `${taskType} Task`);
  };

  const handleModalClose = useCallback(() => closeModal(taskId), [taskId]);

  const modalContentDefault = (
    <ModalDisclaimerContent
      title={content.title}
      subtitle={content.subtitle}
      disclaimer={content.disclaimer}
      disclaimerShowCurrentDate={content.showCurrentDate}
      dowloadDisclaimerLink={link.link}
      dowloadDisclaimerLinkTitle={link.linkTitle}
      onAccept={() => updateConsent(true)}
      onDecline={() => setModalContent(taskId, modalDeclineConfirmation)}
    />
  );
  const modalContentLoading = <ModalLoading />;
  const modalDeclineConfirmation = (
    <ModalConsentDeclineConfirmation
      onCancel={() => setModalContent(taskId, modalContentDefault)}
      onContinue={() => updateConsent(false)}
    />
  );
  const modalSuccess = (agree: boolean) => <ModalConsentSuccess onDone={handleModalClose} agree={agree} />;
  const support = useDefaultSupport();
  const modalFailed = (
    <ErrorModalContent
      onDone={handleModalClose}
      title='We were unable to update your consent'
      support={support}
      location='your tasklist'
    />
  );

  const updateConsent = (agree: boolean): void => {
    setModalContent(taskId, modalContentLoading);
    setDataLayer('taskInfo', { taskId, taskType });
    log({
      loanGuid,
      taskId,
      level: 'debug',
      message: `Consent: Attempting to update consent for ${taskType} task ${JSON.stringify({ agree })}`,
    });
    dispatch(sendConsent(loanGuid, consentType, agree, taskId))
      .then(() => {
        log({
          loanGuid,
          taskId,
          message: `Consent: Success updating consent for ${taskType} task ${JSON.stringify({ agree })}`,
        });
        setModalContent(taskId, modalSuccess(agree));
      })
      .catch(() => {
        log({
          level: 'error',
          loanGuid,
          taskId,
          message: `Consent: Failure updating consent for ${taskType} task ${JSON.stringify({ agree })}`,
        });
        setModalContent(taskId, modalFailed);
      });
  };

  return (
    <Button className='task-button' buttonType='tertiary' title={`${buttonText} ${consentType} task`} onClick={handleButtonClick}>
      {buttonText}
    </Button>
  );
};

export const ConsentTaskTitle = ({
  taskTitle,
  task: {
    'task-completed': completed,
    'custom-task-meta': { consented, agreed },
  },
}: ConsentTaskTitleProps) => {
  const badgeType = completed && consented ? (agreed === true ? 'accepted' : agreed === false ? 'declined' : null) : null;
  return <TaskTitleBadge title={taskTitle} badgeType={badgeType} />;
};
