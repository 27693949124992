import { useSelector } from 'react-redux';
import { IRoute } from './IRoutes';
import { isDev, isLocalDev } from '../../utils/env';
import { useFeatureEnabled } from '../../hooks/useFeatureEnabled';
import Loans from '../../pages/loans/Loans';
import Loan from '../../pages/loan/Loan';
import Profile from '../../pages/profile/Profile';
import HomeValue from '../../pages/homeValue/HomeValue';
import HomeSearch from '../../pages/homeSearch/HomeSearch';
import Login from '../../pages/login/Login';
import Logout from '../../pages/logout/Logout';
import Registration from '../registration/Registration';
import NotFound from '../../pages/notFound/NotFound';
import StyleGuide from '../../pages/styleguide/StyleGuide';
import PersonalLoanDetails from '../../pages/personalLoan/PersonalLoanDetails';
import { PATHS, NAMES, homeLoanPaths } from './paths';
import HELOCLoanDetails from '../../pages/helocLoan/HELOCLoanDetails';
import Insurance from '../../pages/insurance/Insurance';
import { isALoanFunded } from '../../features/loans/loansSlice';
import HELOCLoanServicing from '../../pages/helocLoan/HELOCLoanServicing';
import { getConfigFromStore } from '../../features/config/getConfigFromStore';

export const useRoutes = (): IRoute[] => {
  const config = getConfigFromStore();
  const canShowHomeValue = useSelector(isALoanFunded);
  const isHomeValueEnabled =
    (useFeatureEnabled('home-value') && canShowHomeValue) || (isLocalDev && process.env.REACT_APP_ENABLE_HOME_VAL);
  const isHomeSearchEnabled = useFeatureEnabled('home-search') && config?.links['home-search'];
  const isInsuranceLandingEnabled = useFeatureEnabled('insurance-landing');
  const helocServicingEnabled = useFeatureEnabled('heloc-servicing');
  const styleguideEnabled = isLocalDev || isDev;

  return [
    {
      name: 'homeRedirect',
      redirect: {
        exact: true,
        from: '/',
        to: PATHS.loanCenter,
      },
    },
    {
      name: 'registerRedirect',
      redirect: {
        exact: true,
        from: '/register',
        to: PATHS.registration,
      },
    },
    {
      path: PATHS.loanCenter,
      name: NAMES.loanCenter,
      exact: true,
      routeType: 'privateOnly',
      component: Loans,
    },
    {
      path: PATHS.personalLoan,
      name: NAMES.personalLoan,
      exact: true,
      routeType: 'privateOnly',
      component: PersonalLoanDetails,
      hasComponentInit: true,
    },
    {
      path: PATHS.helocLoan,
      name: NAMES.helocLoan,
      exact: true,
      routeType: 'privateOnly',
      component: HELOCLoanDetails,
      hasComponentInit: true,
    },
    {
      path: PATHS.helocLoanServicing,
      name: NAMES.helocLoanServicing,
      exact: true,
      routeType: 'privateOnly',
      component: HELOCLoanServicing,
      disabled: !helocServicingEnabled,
      hasComponentInit: true,
    },
    {
      path: homeLoanPaths,
      name: NAMES.homeLoan,
      exact: true,
      routeType: 'privateOnly',
      component: Loan,
      hasComponentInit: true,
    },
    {
      path: PATHS.homeValue,
      name: NAMES.homeValue,
      exact: true,
      routeType: 'privateOnly',
      component: HomeValue,
      hasComponentInit: true,
      disabled: !isHomeValueEnabled,
    },
    {
      path: PATHS.homeSearch,
      name: NAMES.homeSearch,
      exact: true,
      routeType: 'privateOnly',
      component: HomeSearch,
      hasComponentInit: true,
      disabled: !isHomeSearchEnabled,
    },
    {
      path: PATHS.profile,
      name: NAMES.profile,
      exact: true,
      routeType: 'privateOnly',
      component: Profile,
    },
    {
      path: PATHS.login,
      name: NAMES.login,
      exact: true,
      routeType: 'publicOnly',
      component: Login,
      hasComponentInit: true,
    },
    {
      path: PATHS.registration,
      name: NAMES.registration,
      exact: true,
      routeType: 'publicOnly',
      component: Registration,
    },
    {
      path: PATHS.insurance,
      name: NAMES.insurance,
      exact: true,
      routeType: 'privateOnly',
      component: Insurance,
      disabled: !isInsuranceLandingEnabled,
    },
    {
      path: PATHS.logout,
      name: NAMES.logout,
      exact: true,
      routeType: 'privateOnly',
      component: Logout,
      hasComponentInit: true,
    },
    {
      path: PATHS.styleguide,
      name: NAMES.styleguide,
      exact: false,
      component: StyleGuide,
      disabled: !styleguideEnabled,
    },
    {
      name: NAMES.notFound,
      routeType: 'privateOnly',
      component: NotFound,
      notFound: true,
    },
  ];
};

export default useRoutes;
