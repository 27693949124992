import { useEffect, useState } from 'react';
import { Task } from '../../../features/tasks/types';
import { useAdditionalTaskDetails } from '../../../hooks/useAdditionalTaskDetails';
import { useTaskText } from '../../../hooks/useTaskText';
import FormButtonComponent from '../../ui/form/FormButtonComponent';
import { AdditionalTaskDetails, DefaultTaskBodyDescription } from '../task/Task';
import Modal from '../../ui/modal/Modal';
import ConfirmationModalContent from '../../ui/modal/ConfirmationModalContent';
import { log } from '../../../utils/logger';
import { serializeError } from '../../../utils/serializeError';
import { setDataLayer } from '../../../features/analytics/setDataLayer';
import api from '../../../features/api';
import { Connect, ConnectErrorEvent } from 'connect-web-sdk';
import Button from '../../ui/button/Button';
import { useDispatch } from 'react-redux';
import { useDefaultSupport } from '../../../hooks/useDefaultSupport';
import ModalLoading from '../../ui/modal/ModalLoading';
import ErrorModalContent from '../../ui/modal/ErrorModalContent';
import ModalHeader from '../../ui/modal/ModalHeader';
import ModalFooter from '../../ui/modal/ModalFooter';
import { removeTask } from '../../../features/tasks/actions';

export const VOAI_TYPES = ['gr_assets_voai'] as const;

const VoaiTaskBody = ({ loanGuid, task }: { loanGuid: string; task: Task<'gr_assets_voai'> }) => {
  const { 'task-id': taskId, 'task-completed': taskCompleted, 'task-type': taskType } = task;
  const taskDescription = useTaskText(task, 'description', loanGuid);
  const additionalDetails = useAdditionalTaskDetails(task, loanGuid);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [voaiUrl, setVoaiUrl] = useState<string>();

  const handleVoaiClick = () => {
    setShowConfirmationModal(true);
  };

  const handleError = () => {
    setLoading(false);
    setError('Verify error. Please try again later.');
  };

  const handleVoaiConfirm = async (receivesDirectDeposit?: boolean) => {
    try {
      setDataLayer('taskInfo', { taskId, taskType });
      log({
        loanGuid,
        taskId,
        level: 'info',
        message: `VOAI: Order${!receivesDirectDeposit ? ', VOA only (no direct deposit)' : ''}`,
      });
      setShowConfirmationModal(false);
      setLoading(true);
      setError(undefined);

      const response = await (receivesDirectDeposit
        ? api.postVoaiOrder(loanGuid, `${window.location.href}`)
        : api.postVoaOrder(loanGuid, `${window.location.href}`));

      if (response?.data?.uiElement) {
        log({ loanGuid, taskId, level: 'info', message: 'VOAI success' });
        setVoaiUrl(response.data.uiElement);
      } else {
        log({ loanGuid, taskId, level: 'info', message: 'VOAI: Order failed no link' });
        handleError();
      }
    } catch (error) {
      log({ loanGuid, taskId, level: 'error', message: `VOAI: Order failed ${serializeError(error)}` });
      handleError();
    }
  };

  useEffect(() => {
    setShowVendorModal(!!voaiUrl);
  }, [voaiUrl]);

  const onVendorModalOpened = () => {
    if (!voaiUrl) return;

    setLoading(false);

    Connect.launch(
      voaiUrl,
      {
        onDone: () => {
          handleVendorClose();
        },
        onCancel: () => {
          handleVendorClose();
        },
        onError: (event: ConnectErrorEvent) => {
          log({ loanGuid, taskId, level: 'error', message: `VOAI: Finicity error ${event}` });
        },
      },
      {
        selector: '#finicity-connect-container',
        overlay: 'rgba(255, 255, 255, 0)',
      },
    );
  };

  const handleVendorClose = () => {
    (window as any).finicityConnect?.destroy();
    // clean out sso link id - start fresh every click
    setVoaiUrl(undefined);
    setShowVendorModal(false);
  };

  const VoiaVerifyAssetsManually = ({
    loanGuid,
    task,
    onError,
  }: {
    loanGuid: string;
    task: Task<'gr_assets_voai'>;
    onError: () => void;
  }) => {
    const { 'task-id': taskId, source, 'task-type': taskType } = task;
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const handleModalOpen = () => {
      setHasError(false);
      setSuccess(false);
    };

    const handleButtonClick = () => {
      log({ loanGuid, taskId, level: 'info', message: 'VOIA Verify Manually: User clicked "Verify assets manually"' });
      setShowModal(true);
    };

    const handleRequestClose = () => {
      log({ loanGuid, taskId, level: 'info', message: 'VOIA Verify Manually: User closed modal' });
      setShowModal(false);
    };

    const handleConfirm = async () => {
      setDataLayer('taskInfo', { taskId, taskType });
      log({ loanGuid, taskId, level: 'info', message: 'VOIA Verify Manually: User confirmed' });
      setLoading(true);
      try {
        await api.patchLoanTask(loanGuid, taskId, { source, 'task-removed': true, 'task-type': taskType });
        setLoading(false);
        setSuccess(true);
        log({
          loanGuid,
          taskId,
          level: 'info',
          message: `VOIA: Verify Manually: Success`,
        });
      } catch (error) {
        setHasError(true);
        log({
          loanGuid,
          taskId,
          level: 'error',
          message: `VOIA: Verify Manually: Task update failed ${serializeError(error)}`,
        });
      }
    };

    const handleCancel = () => {
      log({ loanGuid, taskId, level: 'info', message: 'VOIA Verify Manually: User cancelled' });
      setShowModal(false);
    };

    const handleModalClosed = () => {
      if (hasError) {
        onError();
      }
      if (success) {
        dispatch(removeTask(loanGuid, taskId));
      }
    };

    const handleModalDone = () => {
      setShowModal(false);
    };

    const ModalContentPrompt = (
      <ConfirmationModalContent
        title='Are you sure you want to provide documents manually?'
        textNode={
          <div className='flex flex-col'>
            <p className='mb-4'>
              We will add multiple tasks to your tasklist asking for documents. This may be less convenient than providing
              verification via automated account access.
            </p>
            <p className='font-bold'>This cannot be undone.</p>
          </div>
        }
        confirmButtonText='Yes, continue'
        onConfirm={handleConfirm}
        cancelButtonText='No, cancel'
        onCancel={handleCancel}
        className='px-6 lg:px-0 max-w-lg lg:max-w-full mx-auto'
      />
    );

    const ModalContentLoading = <ModalLoading title='Hang tight while we send your request.' />;

    const support = useDefaultSupport();
    const ModalContentError = (
      <ErrorModalContent
        onDone={handleModalDone}
        title='We were unable to complete this action'
        location='your tasklist'
        support={support}
        className='px-6 lg:px-0 max-w-lg lg:max-w-full mx-auto'
      />
    );

    const ModalContentSuccess = (
      <div className='px-6 lg:px-0 max-w-lg lg:max-w-full mx-auto'>
        <ModalHeader title='You have opted to verify your income & employment manually.' textAlignment='center' />
        <div className='text-center'>
          <p className='my-4'>New tasks will be assigned to upload documents.</p>
        </div>
        <ModalFooter>
          <Button onClick={() => setShowModal(false)} buttonType='primary' size='large'>
            Back to your tasklist
          </Button>
        </ModalFooter>
      </div>
    );

    return (
      <div className='text-center'>
        <Modal
          open={showModal}
          onOpen={handleModalOpen}
          onClosed={handleModalClosed}
          contentLabel='Verification of Assets and Income - Verify Manually'
          onRequestClose={handleRequestClose}
          fullWidth={true}
        >
          {loading ? ModalContentLoading : hasError ? ModalContentError : success ? ModalContentSuccess : ModalContentPrompt}
        </Modal>
        <Button buttonType='tertiary' text='or, verify your income manually' onClick={handleButtonClick} />
      </div>
    );
  };

  const onConfirmModalClose = () => {
    setShowConfirmationModal(false);
  };

  const onVendorModalClose = () => {
    handleVendorClose();
  };

  const VoaiTask = () => {
    return (
      <div className='flex flex-col justify-center'>
        <FormButtonComponent
          containerClassName='pb-3'
          buttonContainerClassName='w-full md:w-fit-content'
          className='w-full md:w-fit-content'
          buttonType='primary'
          error={error}
          loading={loading}
          onClick={handleVoaiClick}
        >
          {taskCompleted ? 'Add/review accounts' : 'Verify assets automatically'}
        </FormButtonComponent>
        <VoiaVerifyAssetsManually loanGuid={loanGuid} task={task} onError={handleError} />
      </div>
    );
  };

  return (
    <>
      <Modal open={showConfirmationModal} contentLabel='Confirm VOAI' onRequestClose={onConfirmModalClose} fullWidth={true}>
        <ConfirmationModalContent
          title='You are eligible to verify your assets, income and employment with a single report!'
          text='Please connect the accounts that you will use for closing funds and all those where your income is directly deposited so we can accurately calculate your income!'
          confirmButtonText={`No I don't receive direct deposit`}
          onConfirm={() => handleVoaiConfirm(false)}
          cancelButtonText='Yes, I receive direct deposit'
          onCancel={() => handleVoaiConfirm(true)}
          footer='Disclaimer: In some instances, we may need additional documentation to verify income and employment'
        />
      </Modal>

      <Modal
        open={showVendorModal}
        contentLabel='Vendor VOAI'
        onRequestClose={onVendorModalClose}
        fullWidth={true}
        onOpened={onVendorModalOpened}
        contentStyle={{ width: '100%', height: '755px' }}
      >
        <div id='finicity-connect-container' className='flex items-center justify-center' />
      </Modal>

      <DefaultTaskBodyDescription taskDescription={taskDescription} />
      {additionalDetails && <AdditionalTaskDetails additionalDetails={additionalDetails} />}
      <VoaiTask />
    </>
  );
};

export default VoaiTaskBody;
