import Circle from '../circle/Circle';

export type NumberBadgeProps = {
  number: number;
  isPrimary?: boolean;
};

export const NumberBadge = ({ number, isPrimary }: NumberBadgeProps) => {
  return (
    <Circle
      className='ml-2'
      bgColor={isPrimary ? 'action-125' : 'action-10'}
      size='1.5rem'
      textSize='small'
      textColor={isPrimary ? 'white' : 'action-125'}
      text={`${number}`}
    />
  );
};
