import { iHELOCLoan } from '../features/helocLoans/types';
import { isHELOCLoanFunded } from '../features/helocLoans/helocLoansSlice';
import { linkTo, PATHS } from '../components/routes/paths';
import { LoanConfig } from '../components/loan/loanCard/ILoanCard';
import useFeatureEnabled from './useFeatureEnabled';

export const useHELOCLoanConfig = (helocLoan: iHELOCLoan | undefined): LoanConfig | undefined => {
  const helocServicingEnabled = useFeatureEnabled('heloc-servicing');

  if (!helocLoan) {
    return undefined;
  }

  const { id } = helocLoan;
  const isFunded = isHELOCLoanFunded(helocLoan);
  return isFunded
    ? {
        actionHref: helocServicingEnabled ? linkTo(PATHS.helocLoanServicing, { guid: id }) : linkTo(PATHS.helocLoan, { guid: id }),
        actionLabel: 'Get payment information',
        loanTypeText: 'HELOC',
        showTracker: false,
        trackerValue: 100,
      }
    : {
        actionHref: linkTo(PATHS.helocLoan, { guid: id }),
        actionLabel: 'Get loan details',
        milestoneText: 'Approved',
        loanTypeText: 'HELOC',
        showTracker: true,
        trackerValue: 50,
      };
};
