import { AlpMilestone, type Loan } from '../../../features/loans/types';
import { selectUser } from '../../../features/user/userSlice';
import { useAppSelector } from '../../../hooks/hooks';
import { formatAddressObject } from '../../../utils/addressFormatter';
import { alpHELOCApplication } from '../../../utils/grLinks';
import Button from '../../ui/button/Button';

export const AlpHELOCMilestoneHeadline = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid, 'alp-heloc-loan': helocLoan, property } = loan;
  const milestone = helocLoan?.milestone;
  const currentUser = useAppSelector(selectUser);
  const userFirstName = currentUser?.name?.first;

  let title = undefined;
  let contentNode = undefined;

  if (milestone === AlpMilestone.CLOSING) {
    title = `Congratulations, you're almost there!`;
    contentNode = <p className='text-xl'>For {formatAddressObject(property)}</p>;
  } else if (milestone === AlpMilestone.CLOSED) {
    title = `Congrats${userFirstName ? `, ${userFirstName}` : ''}!`;
    contentNode = <p className='text-xl'>Sit tight and check back in 3-5 days for servicing information.</p>;
  } else if (milestone === AlpMilestone.DENIED) {
    title = 'Thank you for your recent application for a Guaranteed Rate Home Equity Loan';
    contentNode = (
      <>
        <p className='text-xl'>
          After carefully reviewing your application, we are sorry to advise you that we cannot grant you a loan. Your notice will be added to your documents within 30 days.
        </p>
        <p className='text-xl mt-8'>
          You can click “Apply again” to start a new application.
        </p>
        <Button
          className='mt-10'
          buttonType='primary'
          size='large'
          text='Apply again'
          href={alpHELOCApplication(loanGuid)}
          target='_blank'
        />
      </>
    );
  }

  return (
    <>
      <h1 className='text-marketing-xs md:text-marketing-md mb-2'>{title}</h1>
      <>{contentNode}</>
    </>
  );
};
