import VaLoanImage from '../../../images/va-loans.svg';
import RdcLoanImage from '../../../images/rdc-hta-logo.png';
import { LoanConfig } from '../loanCard/ILoanCard';
import TextBadge from '../../ui/badge/TextBadge';

export const LoanTypeBadge = ({ text }: { text: string }) => {
  return <TextBadge type='ok' text={text} />;
};

export const MilestoneBadge = ({ text }: { text: string }) => {
  return <TextBadge type='ok-secondary' text={text} />;
};

export const MilestoneComposite = ({ loanConfig }: { loanConfig: LoanConfig }) => {
  return (
    <div className='flex items-center gap-x-2'>
      {(loanConfig.isVA && !loanConfig.isRDC) && <img src={VaLoanImage} alt={`VA Loan`} width='44' />}
      {loanConfig.isRDC && <img src={RdcLoanImage} alt={`Realtor.com Loan`} width='44' />}
      {loanConfig.loanTypeText && <LoanTypeBadge text={loanConfig.loanTypeText} />}
      {loanConfig.milestoneText && <MilestoneBadge text={loanConfig.milestoneText} />}
    </div>
  );
};
