import { useState } from 'react';
import LanguageDropdown from '../../../components/multilingual/languageDropdown/LanguageDropdown';
import Dropdown from '../../../components/ui/dropdown/Dropdown';

const DropdownStyleguide = () => {
  const [test1Value, setTest1Value] = useState<string | null>(null);
  const [test2Value, setTest2Value] = useState<string | null>(null);
  const [test3Value, setTest3Value] = useState<string | null>(null);
  const [test5Value, setTest5Value] = useState<string>();
  return (
    <>
      <div className='header-medium mb-8'>Dropdown</div>
      <div className='flex'>
        <Dropdown
          id='test1'
          text={test1Value || 'Test 1'}
          onChange={(_evt, item) => setTest1Value(item.text)}
          items={[
            { text: 'One', value: 'one', dataQa: 'One' },
            { text: 'Two', value: 'two', dataQa: 'Two' },
            { text: 'Three', value: 'three', dataQa: 'Three' },
          ]}
        />
        <Dropdown
          id='test2'
          text={test2Value || 'Test 2'}
          iconName='chevron-down-small'
          iconSize='0.5rem'
          onChange={(_evt, item) => setTest2Value(item.text)}
          items={[
            { text: 'Apple', value: 'apple', dataQa: 'Apple' },
            { text: 'Orange', value: 'orange', dataQa: 'Orange' },
            { text: 'Pineapple', value: 'pineapple', dataQa: 'Pineapple' },
          ]}
        />
        <LanguageDropdown />
      </div>
      <div>
        <Dropdown
          id='test3'
          text={test3Value || 'Test 3'}
          onChange={(_evt, item) => setTest3Value(item.text)}
          items={[
            { text: 'Apple', value: 'apple', dataQa: 'Apple' },
            { text: 'Orange', value: 'orange', dataQa: 'Orange' },
            { text: 'Pineapple', value: 'pineapple', dataQa: 'Pineapple' },
          ]}
          mobile={true}
          mobileTitle='Fruit'
        />
      </div>
      <div>
        <Dropdown
          id='test5'
          text={test5Value}
          onChange={(_evt, item) => setTest5Value(item.text)}
          items={[
            { text: 'Apple', value: 'apple', dataQa: 'Apple' },
            { text: 'Orange', value: 'orange', dataQa: 'Orange' },
            { text: 'Pineapple', value: 'pineapple', dataQa: 'Pineapple' },
          ]}
          mobile={true}
          mobileTitle='Fruit'
        />
      </div>
      <div className='flex flex-row justify-center'>
        <div
          className='border flex flex-end flex-row-reverse overflow-hidden mt-4 mr-4'
          style={{ width: '500px', height: '250px' }}
        >
          <Dropdown
            id='test4'
            text='With Offset'
            items={[
              { text: 'Dropdown option one', value: 'one' },
              { text: 'Dropdown option two', value: 'two' },
              { text: 'Dropdown option three', value: 'three' },
            ]}
            mobile={true}
            mobileTitle='With Offset'
            offsetRight={true}
          />
        </div>
        <div className='border flex flex-end flex-row-reverse overflow-hidden mt-4' style={{ width: '500px', height: '250px' }}>
          <Dropdown
            id='test4'
            text='Without Offset'
            items={[
              { text: 'Dropdown option one', value: 'one' },
              { text: 'Dropdown option two', value: 'two' },
              { text: 'Dropdown option three', value: 'three' },
            ]}
            mobile={true}
            mobileTitle='Without Offset'
          />
        </div>
      </div>
    </>
  );
};

export default DropdownStyleguide;
