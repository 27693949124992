import { isFundedLoan } from '../../../features/loans/helpers';
import { useLoan } from '../../../features/loans/hooks';
import { Loan } from '../../../features/loans/types';
import { usdAmount } from '../../../utils/amount';
import { PATHS, linkTo } from '../../routes/paths';
import Address from '../../ui/address/Address';
import { LoanConfig } from './ILoanCard';
import { LoanCard } from './LoanCard';

export const CESLoanCard = ({ loanGuid }: { loanGuid: string }) => {
  const { data } = useLoan(loanGuid);
  return (
    <CESLoanCardPresentational loan={data as Loan} />
  );
};

export const CESLoanCardPresentational = ({ loan }: { loan: Loan }) => {
  const { 'loan-guid': loanGuid, 'loan-number': loanNumber, property, 'base-loan-amount': loanAmount } = loan;

  const loanConfig: LoanConfig = {
    loanTypeText: 'Home Equity',
    showTracker: !isFundedLoan(loan),
    trackerValue: 50,
    actionHref: isFundedLoan(loan) ?
      linkTo(PATHS.homeLoanServicing, { guid: loanGuid }) :
      linkTo(PATHS.homeLoanOverview, { guid: loanGuid }),
    actionLabel: 'View loan',
  };

  return (
    <LoanCard
      loanNumber={loanNumber}
      loanConfig={loanConfig}
      loanNumberPrefix='Loan #'
      subHeader={`${usdAmount(loanAmount, false)} loan`}
      property={<Address {...property} />}
      loan={loan}
    />
  );
};
