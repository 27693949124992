import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { fetchRateAlert, selectRateAlert, selectRateAlertError, selectRateAlertFetching, selectRateAlertHasData } from './rateAlertSlice';

export const useFetchRateAlert = (loanGuid: string) => {
  const dispatch = useAppDispatch();
  const rateAlertFetching = useAppSelector(state => selectRateAlertFetching(state, loanGuid));
  const rateAlertHasData = useAppSelector(state => selectRateAlertHasData(state, loanGuid));
  const rateAlertError = useAppSelector(state => selectRateAlertError(state, loanGuid));
  const rateAlert = useAppSelector(state => selectRateAlert(state, loanGuid));

  useEffect(() => {
    if (!rateAlertFetching && !rateAlertHasData && !rateAlertError) {
      dispatch(fetchRateAlert(loanGuid));
    }
  }, [dispatch, loanGuid, rateAlertFetching, rateAlertHasData, rateAlertError])

  return {
    rateAlertFetching,
    rateAlertHasData,
    rateAlertError,
    rateAlert,
  };
};
