import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import nextId from '../../../utils/nextId';
import { TooltipProps } from './ITooltip';
import './Tooltip.scss';

const Tooltip = (props: TooltipProps) => {
  const { children, place, className, tooltipClassName, html, tip, event, defaultColor = true, id } = props;
  const tooltipId = id ? id : nextId('tooltip-');
  return (
    <div className={cn('tooltip', { 'text-action hover:text-action-125': defaultColor }, className)}>
      <ReactTooltip
        place={place}
        type='light'
        effect='solid'
        border={true}
        textColor=''
        backgroundColor='#fff'
        borderColor='#d8d8d8'
        className={tooltipClassName}
        html={html}
        event={event}
        role='tooltip'
        id={tooltipId}
      />
      <div tabIndex={0} data-tip={tip} data-for={tooltipId}>
        {children}
      </div>
    </div>
  );
};

export default Tooltip;
