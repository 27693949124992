import { LoanConfig } from '../components/loan/loanCard/ILoanCard';
import { linkTo, PATHS } from '../components/routes/paths';
import { isPersonalLoanFunded } from '../features/personalLoans/personalLoansSlice';
import { iPersonalLoan } from '../features/personalLoans/types';

export const personalLoanTypeText = 'Personal loan';
export const usePersonalLoanConfig = (personalLoan: iPersonalLoan | undefined): LoanConfig | undefined => {
  if (!personalLoan) {
    return undefined;
  }
  const { id } = personalLoan;
  const isFunded = isPersonalLoanFunded(personalLoan);
  return isFunded
    ? {
        actionHref: linkTo(PATHS.personalLoan, { guid: id }),
        actionLabel: 'Get payment information',
        loanTypeText: personalLoanTypeText,
        showTracker: false,
        trackerValue: 100,
      }
    : {
        actionHref: linkTo(PATHS.personalLoan, { guid: id }),
        actionLabel: 'Get loan details',
        milestoneText: 'Approved',
        loanTypeText: personalLoanTypeText,
        showTracker: true,
        trackerValue: 50,
      };
};
