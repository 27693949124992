import { sendEvent } from '../../features/analytics/sendEvent';
import { selectAppLanguage, selectGlobalAdtrk } from '../../features/app/appSlice';
import { selectCurrentLo } from '../../features/user/selectCurrentLo';
import { useAppSelector } from '../../hooks/hooks';
import { mainSite } from '../../utils/grLinks';
import { log } from '../../utils/logger';
import { localeMap } from '../../utils/multilingual';
import { translateUrl } from '../../utils/translateUrl';
import Button from '../ui/button/Button';
import { FacebookShareButtonProps } from './IFacebookShareButton';
import { SocialShareConfigs } from './ISocialShareConfig';

const FacebookShareButton = ({ facebookProps, configKey, fullButton } : { facebookProps: FacebookShareButtonProps; configKey: SocialShareConfigs; fullButton: boolean }) => {
  const { url } = facebookProps;
  const appLanguage = useAppSelector(selectAppLanguage);
  const adtrk = useAppSelector(selectGlobalAdtrk);
  const lo = useAppSelector(selectCurrentLo);
  

  const translatedUrl = translateUrl(url, appLanguage);
  const mainSiteUrl = mainSite( translatedUrl, { adtrk, loid: lo?.['emp-id']});

  const locale = appLanguage && localeMap[appLanguage] ? `&locale=${localeMap[appLanguage]}` : '';
  const shareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(mainSiteUrl)}${locale}&src=sdkpreparse`;

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    sendEvent(`facebookShareClick: ${configKey}`);
    log({ message: `Facebook share clicked: ${configKey}` });
    window.open(shareURL, 'popup', 'width=600,height=600');
    event.stopPropagation();
  };

  return (
    <div
      className='fb-share-button'
      data-href='https://developers.facebook.com/docs/plugins/'
      data-layout='button'
      data-size='small'
    >
      <Button
        target='popup'
        rel='noreferrer'
        buttonType='tertiary'
        text={fullButton ? 'Facebook' : ''}
        title='Share on Facebook'
        className='ml-0 fb-xfbml-parse-ignore'
        onClick={handleShareClick}
        iconPlacement='left'
        iconName='social-facebook'
        iconSize='1.25rem'
        size={fullButton ? 'large' : 'medium'}
      />
    </div>
  );
};

export default FacebookShareButton;
