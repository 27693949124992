import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import api from '../../../features/api';
import { selectAppLanguage } from '../../../features/app/appSlice';
import { Task } from '../../../features/tasks/types';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { useAdditionalTaskDetails } from '../../../hooks/useAdditionalTaskDetails';
import { useTaskText } from '../../../hooks/useTaskText';
import { log } from '../../../utils/logger';
import { DEFAULT_LANGUAGE } from '../../../utils/multilingual';
import nextId from '../../../utils/nextId';
import timezone from '../../../utils/timezone';
import OneClickButton from '../../ui/button/OneClickButton';
import ToggleButtons from '../../ui/button/ToggleButtons';
import Form from '../../ui/form/Form';
import FormButtonComponent from '../../ui/form/FormButtonComponent';
import FormGroup from '../../ui/form/FormGroup';
import { FormGroupElement } from '../../ui/form/IFormGroup';
import Icon from '../../ui/icon/Icon';
import UnsubmittedModal from '../task/UnsubmittedModal';
import { DefaultTaskBodyDescription, AdditionalTaskDetails } from '../task/Task';
import { serializeError } from '../../../utils/serializeError';
import { updateTaskCompleted } from '../../../features/tasks/actions';
import { setDataLayer } from '../../../features/analytics/setDataLayer';

interface Props {
  loanGuid: string;
  task: Task<'affirmations'> | Task<'interpretation_ack'>;
  setAccordionActive?: (active: boolean) => void;
}

interface AffirmationForm {
  response: string | undefined;
  agreed: boolean | undefined;
}

interface FormState {
  byAffirmationResponseId: Record<string, AffirmationForm>;
}

interface FormProps {
  affirmationResponseId: string;
  description: string;
  formState: FormState;
  error: boolean;
  disabled?: boolean;
  setFormDirty: () => void;
  checkFieldError: (responseId?: string) => void;
}

interface FormErrorProps {
  formError: boolean;
}

type BorrowerAffirmationsReconcileFormProps = FormProps & FormErrorProps;

interface ErrorState {
  agreedError: boolean;
  responseError: boolean;
}

const BorrowerAffirmationsReconcileForm = (
  {
    affirmationResponseId,
    description,
    formState,
    error,
    formError,
    disabled,
    setFormDirty,
    checkFieldError,
  }: BorrowerAffirmationsReconcileFormProps
) => {
  const [selected, setSelected] = useState<'yes' | 'no' | undefined>(undefined);
  const [formId] = useState(nextId('borrower-affirmations-reconcile-form-'));
  const [showForm, setShowForm] = useState(false);

  const onClick = (selection: 'yes' | 'no') => {
    setSelected(selection);
    setShowForm(selection === 'no');
    formState.byAffirmationResponseId[affirmationResponseId].agreed = selection === 'yes';
    checkFieldError(affirmationResponseId);
    setFormDirty();
  };

  const onResponseChanged = (event: ChangeEvent<FormGroupElement>) => {
    const value = event.target.value;
    formState.byAffirmationResponseId[affirmationResponseId].response = value;
    checkFieldError(affirmationResponseId);
    setFormDirty();
  };

  const onResponseBlur = () => {
    checkFieldError();
  };

  return (
    <div className='flex flex-col'>
      <div className='flex justify-between'>
        <div className='relative flex flex-col pb-4 mr-5'>
          <p className='mb-0-important'>{description}</p>
          {error && !selected && <p className='text-form-error text-error-important relative h-0'>Please select a response</p>}
        </div>
        <ToggleButtons
          error={error && !selected}
          leftLabel='No'
          rightLabel='Yes'
          disabled={disabled}
          onLeftClick={() => onClick('no')}
          onRightClick={() => onClick('yes')}
        />
      </div>
      {showForm &&
        <>
          <p className='mb-0-important'>If <span className='font-bold'>NO</span>, please explain</p>
          <FormGroup
            className='mt-4'
            required={true}
            label='Explanation'
            id={formId}
            name={formId}
            type='textarea'
            disabled={disabled}
            error={formError ? `Please provide an explanation for selecting 'No'` : undefined}
            defaultErrorMessage={`Please provide an explanation for selecting 'No'`}
            onChange={onResponseChanged}
            onBlur={onResponseBlur}
            removeTextAreaMarginBottom={true}
            value={formState.byAffirmationResponseId[affirmationResponseId].response}
          />
        </>
      }
    </div>
  );
};

const BorrowerAffirmationsAcknowledgeForm = (
  {
    affirmationResponseId,
    description,
    formState,
    error,
    setFormDirty,
    checkFieldError,
  }: FormProps
) => {
  const onAckClick = () => {
    formState.byAffirmationResponseId[affirmationResponseId].agreed = true;
    checkFieldError(affirmationResponseId);
    setFormDirty();
  };

  return (
    <div className='flex justify-between'>
      <div className='relative flex flex-col pb-4 mr-5'>
        <p className='mb-0-important'>{description}</p>
        {error && <p className='text-form-error text-error-important relative h-0'>Please select a response</p>}
      </div>
      <OneClickButton error={error} className='flex-shrink-0' label='I understand' onClick={onAckClick}/>
    </div>
  );
};

const BorrowerAffirmationsIncomplete = ({ loanGuid, task, setAccordionActive }: Props) => {
  const dispatch = useAppDispatch();
  const [formId] = useState(nextId('borrower-affirmations-form-'));
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string>();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const [errorState, setErrorState] = useState<Record<string, ErrorState>>();

  const appLanguage = useAppSelector(selectAppLanguage) || DEFAULT_LANGUAGE;
  const additionalDetails = useAdditionalTaskDetails(task, loanGuid);
  let taskDescription = useTaskText(task, 'description', loanGuid) as string | undefined;
  const { 'task-id': taskId, 'task-type': taskType, 'task-title': taskTitle, 'affirmation-responses': affirmationResponses } = task;

  if (task['task-type'] === 'interpretation_ack') {
    taskDescription = undefined;
  }

  const formStateRef = useRef<FormState>({
    byAffirmationResponseId: {},
  });

  useEffect(() => {
    const initErrors: Record<string, ErrorState> = {};
    for (const affirmationResponse of affirmationResponses) {
      const responseId = affirmationResponse.affirmationResponseId;
      formStateRef.current.byAffirmationResponseId[responseId] = {
        response: undefined,
        agreed: undefined,
      };
      initErrors[responseId] = { agreedError: false, responseError: false };
    }
    setErrorState(initErrors);
  }, [affirmationResponses]);

  /**
   * If responseId is not passed, check the whole form for errors and flag/calm all fields for errors
   * If responseId is passed, check the whole form for errors but only flag/calm the specific field for error
   * @param responseId
   * @returns
   */
  const checkFormSubmitErrors = (responseId?: string) => {
    let hasFormError = false;

    const formState = formStateRef.current;
    const newErrorState = { ...errorState };

    // go thru all responses
    for (const affirmationResponseId of Object.keys(formState.byAffirmationResponseId)) {
      const response = formState.byAffirmationResponseId[affirmationResponseId];
      const shouldFlagCalmError = responseId === undefined || (responseId && responseId === affirmationResponseId);

      let responseError = false;
      if (response.agreed === undefined || (response.agreed === false && !response.response)) {
        responseError = true;
      }

      if (shouldFlagCalmError) {
        newErrorState[affirmationResponseId].agreedError = responseError;
        newErrorState[affirmationResponseId].responseError = responseId === undefined && responseError;
      }

      hasFormError = hasFormError || responseError;
    }

    setErrorState(newErrorState);

    // disable the submit button if error on submit (not when editing)
    if (responseId === undefined) {
      setDisableSubmit(hasFormError);
    } else if (!hasFormError) {
      setDisableSubmit(false);
    }

    return hasFormError;
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setDataLayer('taskInfo', { taskId, taskType });
    log({ loanGuid, taskId, message: `Borrower affirmation attempting form submit ${JSON.stringify({ taskType, taskTitle })}` });
    try {
      const hasError = checkFormSubmitErrors();
      if (hasError) {
        log({ loanGuid, taskId, message: `Borrower affirmation form has errors ${JSON.stringify({ taskType, taskTitle })}` });
        return;
      }
      setSubmitting(true);
      const formState = formStateRef.current;
      const affirmationResponses = Object.keys(formState.byAffirmationResponseId).map(respId => {
        return {
          affirmationResponseId: respId,
          response: formState.byAffirmationResponseId[respId].response,
          agreed: !!formState.byAffirmationResponseId[respId].agreed,
        };
      });
      await api.postTaskDocument(loanGuid, taskId, {
        affirmationResponses: affirmationResponses,
        timezone: timezone(),
        source: task.source,
        'task-type': taskType,
      });
      setFormDirty(false);
      setDisableSubmit(true);
      setSubmitSuccess(true);
      log({ loanGuid, taskId, message: `Borrower affirmation form submit success ${JSON.stringify({ taskType, taskTitle })}` });
      // wait a second then set the task to complete
      setTimeout(() => {
        dispatch(updateTaskCompleted(taskId, true));
      }, 1000);
    } catch (error) {
      setSubmitError('Submit Error. Please try again later.')
      log({ loanGuid, taskId, level: 'error', message: `Borrower affirmation submit failed ${JSON.stringify({ taskType, taskTitle })} ${serializeError(error)}` });
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmitAbandon = () => {
    setFormDirty(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {taskDescription && <DefaultTaskBodyDescription taskDescription={taskDescription} />}
      {additionalDetails && <AdditionalTaskDetails additionalDetails={additionalDetails} />}
      <UnsubmittedModal
        isDirty={formDirty}
        onAbandon={onSubmitAbandon}
        setAccordionActive={setAccordionActive}
        submitType='affirmations'
      />
      {affirmationResponses?.map((affirmation, index) => {
        const affirmationResponseId = affirmation.affirmationResponseId;
        return (
          <div key={affirmationResponseId} className='flex flex-col'>
            {affirmation.affirmationResponseType === 'Reconcile'
              ? <BorrowerAffirmationsReconcileForm
                  affirmationResponseId={affirmationResponseId}
                  description={affirmation.content[appLanguage]}
                  formState={formStateRef.current}
                  error={!!errorState?.[affirmationResponseId].agreedError}
                  formError={!!errorState?.[affirmationResponseId].responseError}
                  checkFieldError={checkFormSubmitErrors}
                  setFormDirty={() => setFormDirty(true)}
                  disabled={submitting || submitSuccess}
                />
              : <BorrowerAffirmationsAcknowledgeForm
                  affirmationResponseId={affirmation.affirmationResponseId}
                  description={affirmation.content[appLanguage]}
                  formState={formStateRef.current}
                  error={!!errorState?.[affirmationResponseId].agreedError}
                  checkFieldError={checkFormSubmitErrors}
                  setFormDirty={() => setFormDirty(true)}
                />
            }
            {index !== affirmationResponses.length - 1 && <hr className='my-6' />}
          </div>
        );
      })}

      {submitSuccess ?
        <div className='flex justify-center items-center mt-5'>
          <Icon name='check-tick' className='text-ok mr-2' size='0.75rem' />
          <p>Your submission was successful</p>
        </div>
      : <FormButtonComponent
          id={`${formId}-submit-button`}
          buttonContainerClassName='mt-6 w-full md:w-fit-content'
          className='w-full md:w-fit-content'
          error={submitError}
          loading={submitting}
          disabled={disableSubmit}
        >
          Submit affirmations
        </FormButtonComponent>
      }
    </Form>
  );
};

export default BorrowerAffirmationsIncomplete;
