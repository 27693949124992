import { selectAppLanguage } from '../features/app/appSlice';
import { selectLoan } from '../features/loans/loansSlice';
import { Task } from '../features/tasks/types';
import { RootState } from '../store/types';
import { useAppSelector } from './hooks';
import { useMultilingual } from './useMultilingual';
import { getTaskById } from '../features/tasks/selectors';

export const useTaskText = (task: Task<any> | undefined, textType: 'title' | 'description', loanGuid: string): string | undefined => {
  const multilingual = useMultilingual();
  const loan = useAppSelector((state: RootState) => selectLoan(state, loanGuid));
  const appLanguage = useAppSelector(selectAppLanguage) as string;
  const taskTitleDefaults = task?.['alternate-language-content']?.taskTitleDefaults;
  const taskDescriptionDefaults = task?.['alternate-language-content']?.taskDescriptionDefaults;

  if (!task) {
    return undefined;
  }

  if (!multilingual || (multilingual && !loan?.['lap?'] && appLanguage === 'en')) {
    switch (textType) {
      case 'title':
        return task['task-title'];
      case 'description':
        return task['task-description'];
    }
  }

  // Multilingual / Non-Lap loan / Spanish Language UI
  // Multilingual / Lap loan / English, Spanish Language UI
  switch (textType) {
    case 'title':
      return taskTitleDefaults?.[appLanguage] || task['task-title'];
    case 'description':
      return taskDescriptionDefaults?.[appLanguage] || task['task-description'];
  }
};

export const useTaskTextById = (taskId: string, textType: 'title' | 'description', loanGuid: string) => {
  const task = useAppSelector((state: RootState) => getTaskById(state, taskId));
  return useTaskText(task, textType, loanGuid);
};
