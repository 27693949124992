import { useSelector } from 'react-redux';
import type { RootState } from '../../store/types';
import { content } from '../../features/tenant/tenant';
import { useIsAuth, useIsAuthValidated } from '../../features/auth/authSlice';
import useSearchParams from '../../hooks/useSearchParams';
import { selectHasConfig, selectError } from '../../features/config/configSlice';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { MainLayout } from '../../components/layout/Layout';
import Error from '../../components/error/Error';
import Button from '../../components/ui/button/Button';
import { PATHS } from '../../components/routes/paths';
import { selectGlobalError, updateGlobalError } from '../../features/app/appSlice';
import { RequestError } from '../../interfaces/IRequest';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { sendEvent } from '../../features/analytics/sendEvent';
import { log } from '../../utils/logger';
import { useEffect, useRef } from 'react';
import { AxiosError } from 'axios';

/**
 * Unexpected error fallback UI for ErrorBoundary component
 */
const UnexpectedError = ({ cause }: { cause?: string; }) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useIsAuth();
  const isAuthValidated = useIsAuthValidated();
  const hasConfig = useAppSelector(selectHasConfig);
  const hasLoggedShown = useRef<boolean>(false);

  // searchParamErrorCodeKey from query param is an encoded string, that decodes to a key from the errors dictionary in config
  const searchParamErrorCodeKey = useSearchParams().get('_t');
  // Checks if error param exists, looks up from error code dictionary passed by the API to make sure valid error
  const searchParamError = useSelector((state: RootState) => searchParamErrorCodeKey && selectError(state, searchParamErrorCodeKey));
  // Checks for globalError from Redux state
  const globalError = useAppSelector(selectGlobalError);
  const globalErrorCause = (globalError as RequestError)?.causes?.[0];
  const globalErrorAxiosCode = (globalError as AxiosError)?.code;
  // Grab the error code from search param error or from global error cause
  const { code } = searchParamError || globalErrorCause || {};
  const documentTitle = code && ['E_OMBRW', 'E_RMMBWR'].includes(code) ? 'Authentication error' : globalErrorAxiosCode ? 'App loading error' : 'Unknown error';

  // update copy and cta based on authentication
  const text = `Let's ${!isAuthenticated ? 'try again' : 'go home'}. If the problem continues, contact us for help.`;
  const primaryButtonText = !isAuthenticated ? 'Try again' : 'Return to loan center';
  const error = (code || globalErrorAxiosCode) ? `Error ${code || globalErrorAxiosCode}` : 'Unknown error';

  let secondaryButtonHref;
  if (content.supportEmail) {
    secondaryButtonHref = `mailto:${content.supportEmail.toLowerCase()}`;
    (code || globalErrorAxiosCode) && (secondaryButtonHref += `?subject=myAccount error ${code || globalErrorAxiosCode}`);
  }

  const handleReturnToLoanCenterClick = () => {
    sendEvent('error: unexpectedError: returnToLoanCenterClick', { code: code, isAuthenticated: isAuthenticated });
    log({ level: 'info', message: 'error: unexpectedError: returnToLoanCenterClick' });
    if (isAuthenticated) {
      // Back to loan center
      window.location.replace(PATHS.loanCenter);
    } else if (code) {
      // Try again. If Okta error then try auth route again by routing use to home route
      window.location.replace('/');
    } else {
      // Reloads the current page
      window.location.reload();
    }
  };

  const handleRequestHelpClick = () => {
    sendEvent('error: unexpectedError: requestHelpClick', { code: code, isAuthenticated: isAuthenticated });
    log({ level: 'info', message: 'error: unexpectedError: requestHelpClick' });
  }

  useEffect(() => {
    if (!globalError) {
      dispatch(updateGlobalError('ErrorBoundary'));
    }
  }, [dispatch, globalError]);

  useEffect(() => {
    if (!hasLoggedShown.current && ((hasConfig && isAuthValidated) || globalError)) {
      hasLoggedShown.current = true;
      sendEvent('error: unexpectedError shown', { code: code });
      log({
        level: 'info',
        message: `error: unexpectedError UI shown ${JSON.stringify({
          code,
          cause,
          ...(searchParamError && { searchParamError }),
          ...(globalError && { globalError }),
        })}`,
      });
    }
  }, [code, cause, globalError, hasConfig, isAuthValidated, searchParamError]);

  useDocumentTitle(documentTitle);

  return (
    <MainLayout>
      <div className='main-content error-view'>
        <Error
          title='Oops! Something went wrong.'
          text={text}
          error={error}
          cta={
            <div className='pt-4 lg:flex lg:justify-center lg:flex-row-reverse'>
              <Button
                buttonType='primary'
                size='large'
                className='error-view-btn w-full lg:w-auto'
                onClick={handleReturnToLoanCenterClick}
                style={{ minWidth: '248px' }}
              >
                {primaryButtonText}
              </Button>
              {secondaryButtonHref && (
                <Button
                  href={secondaryButtonHref}
                  target='_blank'
                  buttonType='secondary'
                  size='large'
                  onClick={handleRequestHelpClick}
                  className='error-view-btn w-full lg:w-auto mt-4 lg:mt-0 lg:mr-6'
                  style={{ minWidth: '248px' }}
                >
                  Request help
                </Button>
              )}
            </div>
          }
        />
      </div>
    </MainLayout>
  );
};

export default UnexpectedError;
