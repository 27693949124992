import { formatDate, FORMAT_LONG, FORMAT_SHORT } from '../../../utils/date';
import { LoanCard } from './LoanCard';
import {
  getDisplayedLoanNumber,
  getNextPaymentDate,
  isPersonalLoanFunded,
  selectPersonalLoanById,
} from '../../../features/personalLoans/personalLoansSlice';
import { useAppSelector } from '../../../hooks/hooks';
import { usdAmount } from '../../../utils/amount';
import { iPersonalLoan } from '../../../features/personalLoans/types';
import Time from '../../ui/time/Time';
import { TimeProps } from '../../ui/time/ITime';
import { usePersonalLoanConfig } from '../../../hooks/usePersonalLoanConfig';

export const getLoanAmount = (loanAmount: number | undefined) => {
  return loanAmount ? `${usdAmount(loanAmount, false, false)} personal loan` : undefined;
};

export const FundedSubheader = ({ personalLoan }: { personalLoan: iPersonalLoan }) => {
  const paymentDueDate = getNextPaymentDate(personalLoan);
  if (paymentDueDate) {
    const dateInfo = {
      date: paymentDueDate,
      label: `Next payment on ${formatDate(paymentDueDate, FORMAT_SHORT)}`,
      title: formatDate(paymentDueDate, FORMAT_LONG),
    };
    return <Time {...(dateInfo as TimeProps)} />;
  }
  // Funded loan missing next payment due date
  return null;
};

export const PersonalLoanCard = ({ personalLoanId }: { personalLoanId: string }) => {
  const personalLoan = useAppSelector(state => selectPersonalLoanById(state, personalLoanId)) as iPersonalLoan;
  const loanNumber = getDisplayedLoanNumber(personalLoan);
  const isFunded = isPersonalLoanFunded(personalLoan);
  const loanConfig = usePersonalLoanConfig(personalLoan);

  return loanConfig ? (
    <LoanCard
      loanNumber={loanNumber}
      loanConfig={loanConfig}
      loanNumberPrefix='Personal loan #'
      subHeader={!isFunded ? 'Funds available soon' : FundedSubheader({ personalLoan })}
      loanAmount={getLoanAmount(personalLoan.loanDetails?.amount)}
    />
  ) : null;
};
