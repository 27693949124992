import { useEffect, useRef } from 'react';
import cn from 'classnames';
import { useUser } from '../../features/user/userSlice';
import nextId from '../../utils/nextId';
import HeaderLogo from '../header/HeaderLogo';
import Button from '../ui/button/Button';
import Icon from '../ui/icon/Icon';
import { MainNavigationLinks, SecondaryNavigationLinks } from './Navigation';
import useError from '../../hooks/useError';

/**
 * Left flyout for full screen and mobile navigation
 */

const TopMenuTop = () => {
  const { showingError } = useError();

  if (showingError) return null;

  return (
    <div>
      <nav aria-label='Main navigation' role='navigation'>
        <MainNavigationLinks className='w-full space-y-2' />
      </nav>
    </div>
  );
};

const TopMenuBottom = () => {
  const user = useUser();

  const welcomeString = (
    <>
      Welcome{user?.name?.['first'] && ', '}
      {user?.name?.['first'] && <span className='OneLinkNoTx'>{user?.name?.['first']}</span>}
    </>
  );

  return (
    <div className='mt-9 lg:mt-10 ml-3 flex flex-col lg:hidden'>
      <div className='flex pb-2 items-center'>
        <Icon name='user-circle' className='mr-4' size='1.125rem' aria-label='Profile' />
        <span data-qa='user-name-full' className='body-large'>
          {welcomeString}
        </span>
      </div>
      <SecondaryNavigationLinks className='pl-10' />
    </div>
  );
};

const TopMenu = ({ id, closeFn }: { id?: string; closeFn: () => void }) => {
  id = id || nextId('flyout-');
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleCloseMenu = () => {
    closeFn?.();
  };

  useEffect(() => {
    const parentEl = containerRef.current;

    if (!parentEl) return;

    const eventListener = (evt: MouseEvent): void => {
      const target = evt.target as HTMLElement;
      if (target.closest('button, a')) {
        closeFn?.();
      }
    };

    parentEl.addEventListener('click', eventListener);
    return () => {
      parentEl.removeEventListener('click', eventListener);
    };
  }, [closeFn]);

  const logoContainerClassnames = 'flex flex-row-reverse justify-between bg-white w-full fixed left-0 top-0 px-6 py-4';
  const logoContainerClassnamesLarge = 'lg:flex-row lg:static lg:w-auto lg:flex-row lg:p-0';

  return (
    <div className='p-6 pt-4 flex flex-col h-full overflow-auto' id={id} ref={containerRef}>
      <div className={cn(logoContainerClassnames, logoContainerClassnamesLarge)} style={{zIndex: 3}}>
        <HeaderLogo className='justify-start flex-grow ml-4 lg:ml-0' />
        <Button
          className='px-2 -mx-2 -ml-1'
          buttonType='icon'
          icon={<Icon name='close' size='1rem' hoverEffect={true} />}
          rotate={true}
          text='Close menu'
          onClick={handleCloseMenu}
          buttonRef={closeButtonRef}
        />
      </div>
      <section id='top-menu' className='top-menu-section w-full h-full lg:w-82'>
        <div className='flex flex-col h-full'>
          <div className='flex flex-col h-full justify-between'>
            <TopMenuTop />
            <TopMenuBottom />
          </div>
        </div>
      </section>
    </div>
  );
};

export default TopMenu;
