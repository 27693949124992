import { useLoan } from '../../../features/loans/hooks';
import { Loan } from '../../../features/loans/types';
import { useAlpHELOCLoanConfig } from '../../../hooks/useAlpHELOCLoanConfig';
import { usdAmount } from '../../../utils/amount';
import Address from '../../ui/address/Address';
import { LoanCard } from './LoanCard';

export const AlpHELOCLoanCard = ({ loanGuid }: { loanGuid: string }) => {
  const { data } = useLoan(loanGuid);
  return (
    <AlpHELOCLoanCardPresentational loan={data as Loan} />
  );
};

export const AlpHELOCLoanCardPresentational = ({ loan }: { loan: Loan }) => {
  const { 'loan-number': loanNumber, property, 'base-loan-amount': loanAmount } = loan;
  const loanConfig = useAlpHELOCLoanConfig(loan);
  return (
    <LoanCard
      loanNumber={loanNumber}
      loanConfig={loanConfig}
      loanNumberPrefix='Loan #'
      subHeader={`${usdAmount(loanAmount, false)} loan`}
      property={<Address {...property} />}
      loan={loan}
    />
  );
};
