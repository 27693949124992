import Carousel from '../../../components/ui/carousel/Carousel';

const CarouselStyleguide = () => {
  return (
    <>
      <div className='header-medium mb-8'>Carousel</div>
      <Carousel />
    </>
  );
};

export default CarouselStyleguide;
