import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchTask } from '../../../features/tasks/actions';
import { SwiperItem } from '../../../features/tasks/types';
import Button from '../../ui/button/Button';
import DataItem from '../../ui/datalist/dataItem/DataItem';
import Datalist from '../../ui/datalist/Datalist';
import { taskModalSubject, openModal } from '../tasklist/TaskModal';
import { ModalState } from '../tasklist/iTaskModal';
import { PaymentTaskBodyProps } from './IPayment';
import PaymentModal from './PaymentModal';
import './Payment.scss';
import { selectAppLanguage } from '../../../features/app/appSlice';
import { useAppSelector } from '../../../hooks/hooks';
import { log } from '../../../utils/logger';
import { setDataLayer } from '../../../features/analytics/setDataLayer';

export const PAYMENT_TYPES = ['payment_fee'] as const;

export const PaymentTaskBody = ({ task, loanGuid }: PaymentTaskBodyProps) => {
  const dispatch = useDispatch();
  const { 'task-id': taskId, 'task-title': taskTitle, source, 'task-type': taskType } = task;
  const swiperId = task['custom-task-meta']?.swiper?.id;
  const appLanguage = useAppSelector(selectAppLanguage);

  const getDataItems = (items: SwiperItem[]) =>
    items.map((item: SwiperItem, index: number) => <DataItem key={index} label={item.desc} value={`$${item.amount}`}></DataItem>);

  const handleClick = () => {
    setDataLayer('taskInfo', { taskId, taskType });
    log({
      message: `paymentTask: Payment modal opened ${JSON.stringify({
        swiperId,
        appLanguage,
      })}`,
      loanGuid,
      taskId,
    });
    openModal(taskId, <PaymentModal swiperId={swiperId} taskTitle={taskTitle} language={appLanguage} />, 'Payment Task');
  };

  const handleModalClose = useCallback(() => {
    log({
      message: `paymentTask: Payment modal closed ${JSON.stringify({
        swiperId,
        appLanguage,
      })}`,
      loanGuid,
      taskId,
    });
    dispatch(fetchTask(loanGuid, taskId, source));
  }, [dispatch, loanGuid, taskId, source, swiperId, appLanguage]);

  const handleModalSubject = useCallback(
    (modal: ModalState) => {
      if (modal.id === taskId && !modal.open) {
        handleModalClose();
      }
    },
    [handleModalClose, taskId],
  );

  useEffect(() => {
    const subscription = taskModalSubject.subscribe(handleModalSubject);
    return () => {
      subscription.unsubscribe();
    };
  }, [handleModalSubject]);

  return (
    <div className='task-body text-sm lg:text-base'>
      {swiperId ? (
        <>
          <p>Please enter a valid credit card to process your payment through our secure portal.</p>
          <div className='payment-summary mt-4'>
            <div className='dlist-headline'>Fee summary</div>
            {task['custom-task-meta']?.swiper?.items && getDataItems(task['custom-task-meta']?.swiper?.items).length > 0 ? (
              <Datalist inline totalAmount={`$${task['custom-task-meta']?.swiper?.['total-amount']}`} totalTitle='Total loan fees'>
                {getDataItems(task['custom-task-meta']?.swiper?.items)}
              </Datalist>
            ) : null}
          </div>
          {!task['task-completed'] && swiperId && (
            <div className='flex justify-center mt-6'>
              <Button buttonType='primary' onClick={handleClick} title='Pay now'>
                Pay now
              </Button>
            </div>
          )}
        </>
      ) : (
        'Looks like there are no payment fees for your loan at the moment...'
      )}
    </div>
  );
};
