import cn from 'classnames';
import { IconProps } from './IIcon';
import { isEmpty } from '../../../utils/object';
import ConditionalWrapper from '../conditionalWrapper/ConditionalWrapper';

const Icon = (props: IconProps) => {
  const { 'aria-label': ariaLabel, 'data-qa': dataQa, className, name, size, hoverEffect = false } = props;
  const style = { ...props.style, ...(size && { fontSize: size }) };

  return (
    <ConditionalWrapper
      condition={hoverEffect}
      wrapper={children => {
        return (
          <span className='flex items-center justify-center rounded-full hover:text-body-100 hover:bg-icon-hover h-10 w-10 relative'>
            {children}
          </span>
        );
      }}
    >
      <i
        className={cn('icon', `icon-${name}`, className)}
        data-ui='icon'
        role='img'
        aria-label={ariaLabel || name}
        data-qa={dataQa}
        {...(!isEmpty(style) && { style })}
      />
    </ConditionalWrapper>
  );
};

export default Icon;
