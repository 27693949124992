import { useRef } from 'react';
import { LoanCardProps, LoanCardAlertProps } from './ILoanCard';
import { MilestoneComposite } from '../milestone/MilestoneComposite';
import { CircularTracker } from '../../ui/tracker/CircularTracker';
import Button from '../../ui/button/Button';
import Alert from '../../ui/alert/Alert';
import Card from '../../ui/card/Card';
import { isExternalUrl } from '../../../utils/grLinks';
import SocialShareComponent from '../../social/SocialShareComponent';

const LoanCardAlert = ({ type, text, buttonHref, buttonText }: LoanCardAlertProps) => {
  const isExternal = !!(buttonHref && isExternalUrl(buttonHref));
  const buttonProps =
    buttonHref && buttonText
      ? {
          text: buttonText,
          title: buttonText,
          to: !isExternal ? buttonHref : undefined,
          href: isExternal ? buttonHref : undefined,
        }
      : undefined;
  return <Alert className='mt-4' showClose={false} type={type} description={text} buttonProps={buttonProps} />;
};

export const LoanCard = ({
  loanNumber,
  loanConfig,
  loanNumberPrefix,
  subHeader,
  property,
  loanAmount,
  configKey,
}: LoanCardProps) => {
  const anchorRef = useRef(null);
  const isLoanActionExternal = !!(loanConfig.actionHref && isExternalUrl(loanConfig.actionHref));

  const onCardClick = (evt: React.MouseEvent<HTMLElement>): void => {
    const link = anchorRef?.current as HTMLButtonElement | null;
    if (link?.click) {
      link.click();
    }
  };

  return (
    <Card
      className='bg-white px-6 md:px-0'
      dropShadow={true}
      anchorRef={anchorRef}
      onClick={loanConfig.actionLabel ? onCardClick : undefined}
      {...(loanConfig.actionLabel && { title: loanConfig.actionLabel })}
    >
      <div className={`grid ${loanConfig.showTracker ? 'grid-cols-12' : 'md:ml-6 grid-cols-9'}`}>
        {loanConfig.showTracker && (
          <div className='hidden md:flex md:col-span-3 justify-center items-center'>
            <CircularTracker size='large' value={loanConfig.trackerValue} />
          </div>
        )}
        <div className={`${loanConfig.showTracker ? 'col-span-10 md:col-span-8' : 'col-span-8'}`}>
          <div className='mb-2'>
            <MilestoneComposite loanConfig={loanConfig} />
          </div>
          <div className='mb-4 text-xl font-bold'>
            {property}
            {loanAmount}
          </div>
          <div className='grid grid-cols-3 gap-0 md:gap-2.5'>
            {subHeader && (
              <div className='col-span-3 md:col-span-1'>
                {subHeader}
              </div>
            )}
            {loanNumber && (
              <p className='col-span-3 md:col-span-1'>
                {loanNumberPrefix}{loanNumber}
              </p>
            )}
            {configKey && (
              <div className='col-span-3 md:col-span-1'>
                <SocialShareComponent configKey={configKey} />
              </div>
            )}
          </div>
        </div>
        {loanConfig.actionHref && loanConfig.actionLabel && (
          <div
            className={`flex justify-end pr-0 md:pr-3 h-full ${loanConfig.showTracker ? 'col-span-2 md:col-span-1' : 'col-span-1'}`}
            style={{ height: 'min-content', position: 'relative', bottom: '8px' }}
          >
            <Button
              anchorRef={anchorRef}
              buttonType='icon'
              text={loanConfig.actionLabel}
              title={loanConfig.actionLabel}
              iconSize='1.25rem'
              iconName='chevron-right-large'
              iconHoverEffect={true}
              {...(!isLoanActionExternal && { to: loanConfig.actionHref })}
              {...(isLoanActionExternal && { href: loanConfig.actionHref, target: '_blank' })}
            />
          </div>
        )}
      </div>
      {loanConfig.alertType && loanConfig.alertText && (
        <div className='px-0 md:px-6'>
          <LoanCardAlert
            type={loanConfig.alertType}
            text={loanConfig.alertText}
            buttonHref={loanConfig.alertButtonHref}
            buttonText={loanConfig.alertButtonText}
          />
        </div>
      )}
    </Card>
  );
};
