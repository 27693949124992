import { ChangeEvent, useRef, FormEvent } from 'react';
import { isEmail } from '../ui/form/validations';
import Form from '../ui/form/Form';
import FormButton from '../ui/form/FormButton';
import FormGroup from '../ui/form/FormGroup';
import Tel from '../ui/tel/Tel';
import Button from '../ui/button/Button';
import FormError from '../ui/form/FormError';
import { FormGroupElement } from '../ui/form/IFormGroup';
import { oktaLogin } from '../../utils/grLinks';
import { content } from '../../features/tenant/tenant';
import { useDefaultSupport } from '../../hooks/useDefaultSupport';
import { unformatPhoneNumber } from '../../utils/phone';

type FormProps = {
  value: string;
  error: string;
  handleChange: (changeEvent: ChangeEvent<FormGroupElement>) => void;
  handleBlur: (changeEvent: ChangeEvent<FormGroupElement>) => void;
  disabled: boolean;
};

const EmailFormComponent = ({value, error, handleChange, handleBlur, disabled}: FormProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <FormGroup
      inputRef={inputRef}
      id='email'
      label='Email'
      type='email'
      value={value}
      error={error}
      required={true}
      autoComplete='email'
      autoCorrect='off'
      autoCapitalize='off'
      spellCheck='false'
      maxLength='128'
      className='registration__form-group w-full'
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
    />
  );
};

const SsnFormComponent = ({value, error, handleChange, handleBlur, disabled}: FormProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <FormGroup
      inputRef={inputRef}
      id='ssn'
      label='Last 4 digits of your SSN'
      value={value}
      error={error}
      inputMode='numeric'
      pattern='[0-9]*'
      required={true}
      autoComplete='off'
      spellCheck='false'
      maxLength='4'
      className='registration__form-group w-full'
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
    />
  );
};

const LockedMessageComponent = () => {
  const { company: companyName } = content;
  const { phone } = useDefaultSupport();
  return (
    <p className='mb-8 text-center'>
      For your security, we've blocked further attempts to validate your account.
      Contact {companyName} Support at&nbsp;
      <Tel label={phone} tel={unformatPhoneNumber(phone)} /> for assistance.
    </p>
  );
};

type RegistrationFormProps = {
  isAccountLocked: boolean;
  isSubmitting: boolean;
  formError: string;
  // email
  email: string;
  emailError: string;
  handleEmailOnChange: (changeEvent: ChangeEvent<FormGroupElement>) => void;
  handleValidateEmail: () => void;
  handleSubmitEmail: (formEvent: FormEvent) => void;
  canUserRegisterWithEmail: boolean;
  // ssn
  ssn: string;
  ssnError: string;
  handleSSNMaxLength: (changeEvent: ChangeEvent<FormGroupElement>) => void;
  handleValidateSSN: () => void;
  handleSubmitEmailAndSsn: (formEvent: FormEvent) => void;
};

export const EmailRegistrationForm = (props: RegistrationFormProps) => {
  return (
    <Form
      className='flex flex-col items-center registration__form mx-auto mt-5 lg:mt-4'
      onSubmit={props.handleSubmitEmail}
    >
      <h1 className='text-marketing-xs mb-3 text-center'>Welcome to myAccount</h1>
      <p className='text-lg mb-3 text-left w-full'>Please enter your email to continue.</p>
      <EmailFormComponent
        value={props.email}
        error={props.emailError}
        handleChange={props.handleEmailOnChange}
        handleBlur={props.handleValidateEmail}
        disabled={props.isAccountLocked} />
      {props.isAccountLocked && <LockedMessageComponent />}
      <FormButton
        className='w-full h-12 mb-3'
        disabled={props.isAccountLocked || !isEmail(props.email)}
        loading={props.isSubmitting}
      >
        Continue
      </FormButton>
      <p className='w-full mb-2'>Already have an account? <Button className='font-bold' buttonType='inline' href={oktaLogin()}>Log in</Button></p>
      <FormError error={props.formError} />
    </Form>
  );
};

export const EmailSsnRegistrationForm = (props: RegistrationFormProps) => {
  return (
    <Form
      className='flex flex-col items-center registration__form mx-auto mt-5 lg:mt-4'
      onSubmit={props.handleSubmitEmailAndSsn}
    >
      <h1 className='text-marketing-xs mb-4 text-center'>Welcome to myAccount</h1>
      <p className='text-lg mb-3 text-left w-full'>Please enter the last four digits of your SSN and we will send you an account activation link.</p>
      <EmailFormComponent
        value={props.email}
        error={props.emailError}
        handleChange={props.handleEmailOnChange}
        handleBlur={props.handleValidateEmail}
        disabled={props.isAccountLocked} />
      <SsnFormComponent
        value={props.ssn}
        error={props.ssnError}
        handleChange={props.handleSSNMaxLength}
        handleBlur={props.handleValidateSSN}
        disabled={props.isAccountLocked} />
      {props.isAccountLocked && <LockedMessageComponent />}
      <FormButton
        className='w-full h-12 mb-2'
        disabled={props.isAccountLocked || !isEmail(props.email) || props.ssn.length !== 4}
        loading={props.isSubmitting}
      >
        Send Link
      </FormButton>
      <FormError error={props.formError} />
    </Form>
  );
};
