import {
  GeneralSupport,
  RetentionSupport,
  LoanSupport,
  GeneralSupportRow,
  RetentionSupportRow,
  WrapLoanSupportFooter,
  LoanSupportFooter,
} from '../../../components/loan/support/Support';
import mockLoanInprocess from '../../../mocks/loanInprocess';
import mockLoanFunded from '../../../mocks/loanFunded';
import type { LoanApplicationStatus } from '../../../features/loans/types';
import Button from '../../../components/ui/button/Button';
import { content } from '../../../features/tenant/tenant';
import { RateAlertPresentational } from '../../../components/rateAlert/RateAlert';

const SupportStyleguide = () => {
  const loanInProcess = { ...mockLoanInprocess };
  const loanInProcessNoMc = { ...mockLoanInprocess, 'application-status': 'editable' as LoanApplicationStatus };
  const loanInProcessRealtor = {
    ...loanInProcessNoMc,
    team: {
      ...loanInProcessNoMc.team,
      realtor: {
        email: 'jack.sampleton@email.com',
        id: '1',
        name: 'Jack Sampleton',
        phone: '(000) 000-0000',
        'role-name': 'Real Estate Agent',
        'role-key': 'realtor',
        title: 'Real Estate Agent',
        'agency-name': 'Real Estate Agency',
      },
    },
  };
  const loanFunded = { ...mockLoanFunded };

  const mockRateAlert = {
    invitationNumber: "124",
    borrowerInfo: {
      firstName: "Paul",
      lastName: "Last Paul",
      email: "testemail@rate.com",
    },
    loanInfo: {
      interestRate: 8.0000,
      monthlyPayment: 2935.06,
      loanAmount: 400000.00,
      numberOfPayments: 360,
      loanOfficerEmpId: "12657",
      subjectPropertyState: "CA"
    },
    data: {
      targetYearlySavingsAmount: 1200.00,
      targetRate: 7.9550,
      targetMonthlyPaymentAmount: 2922.56,
      consentDateTime: "2024-01-24T16:56:29.649882"
    },
    monitorInfo: {
      monthlyPaymentAmount: 1200.00,
      rate: 8.00,
      estimatedYearlySavingsAmount: 1000, 
      rateEffectiveDate: "2024-01-24T16:56:29.649882",
    },
  };

  return (
    <>
      <div className='header-medium mb-8'>Support</div>

      <div className='header-medium my-8'>General Support Row</div>
      <div className='w-full border grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
        <div className='flex bg-inactive-10 justify-center items-center col-span-4 md:col-span-8' style={{ minHeight: '100px' }}>
          I AM CONTENT
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-4 xl:col-span-3'>
          <div className='flex flex-col'>
            <Button buttonType='tertiary' className='text-left mb-2' text='Pretend helpful link 1' />
            <Button buttonType='tertiary' className='text-left' text='Pretend helpful link 2' />
          </div>
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-12 border-t border-gray-25'>
          <WrapLoanSupportFooter>
            <GeneralSupportRow />
          </WrapLoanSupportFooter>
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-12 py-4'>Footer</div>
      </div>

      {(content.retentionEmail || content.retentionPhone) && (
        <>
          <div className='header-medium my-8'>Retention Support Row</div>
          <div className='w-full border grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
            <div
              className='flex bg-inactive-10 justify-center items-center col-span-4 md:col-span-8'
              style={{ minHeight: '100px' }}
            >
              I AM CONTENT
            </div>
            <div className='col-span-4 md:col-span-8 lg:col-span-4 xl:col-span-3'>
              <div className='flex flex-col'>
                <Button buttonType='tertiary' className='text-left mb-2' text='Pretend helpful link 1' />
                <Button buttonType='tertiary' className='text-left' text='Pretend helpful link 2' />
              </div>
            </div>
            <div className='col-span-4 md:col-span-8 lg:col-span-12 border-t border-gray-25'>
              <WrapLoanSupportFooter>
                <RetentionSupportRow />
              </WrapLoanSupportFooter>
            </div>
            <div className='col-span-4 md:col-span-8 lg:col-span-12 py-4'>Footer</div>
          </div>
        </>
      )}

      <div className='header-medium my-8'>Team Support with MC Row</div>
      <div className='w-full border grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
        <div className='flex bg-inactive-10 justify-center items-center col-span-4 md:col-span-8' style={{ minHeight: '100px' }}>
          I AM CONTENT
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-4 xl:col-span-3'>
          <div className='flex flex-col'>
            <Button buttonType='tertiary' className='text-left mb-2' text='Pretend helpful link 1' />
            <Button buttonType='tertiary' className='text-left' text='Pretend helpful link 2' />
          </div>
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-12 border-t border-gray-25'>
          <WrapLoanSupportFooter>
            <LoanSupportFooter loan={loanInProcess} />
          </WrapLoanSupportFooter>
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-12 py-4'>Footer</div>
      </div>

      <div className='header-medium my-8'>Team Support with Realtor Row</div>
      <div className='w-full border grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
        <div className='flex bg-inactive-10 justify-center items-center col-span-4 md:col-span-8' style={{ minHeight: '100px' }}>
          I AM CONTENT
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-4 xl:col-span-3'>
          <div className='flex flex-col'>
            <Button buttonType='tertiary' className='text-left mb-2' text='Pretend helpful link 1' />
            <Button buttonType='tertiary' className='text-left' text='Pretend helpful link 2' />
          </div>
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-12 border-t border-gray-25'>
          <WrapLoanSupportFooter>
            <LoanSupportFooter loan={loanInProcessRealtor} />
          </WrapLoanSupportFooter>
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-12 py-4'>Footer</div>
      </div>

      <div className='header-medium my-8'>Team Support no MC or Realtor Row</div>
      <div className='w-full border grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
        <div className='flex bg-inactive-10 justify-center items-center col-span-4 md:col-span-8' style={{ minHeight: '100px' }}>
          I AM CONTENT
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-4 xl:col-span-3'>
          <div className='flex flex-col'>
            <Button buttonType='tertiary' className='text-left mb-2' text='Pretend helpful link 1' />
            <Button buttonType='tertiary' className='text-left' text='Pretend helpful link 2' />
          </div>
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-12 border-t border-gray-25'>
          <WrapLoanSupportFooter>
            <LoanSupportFooter loan={loanInProcessNoMc} />
          </WrapLoanSupportFooter>
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-12 py-4'>Footer</div>
      </div>

      <div className='header-medium my-8'>Home Loans Support with Servicing Row</div>
      <div className='w-full border grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
        <div className='flex bg-inactive-10 justify-center items-center col-span-4 md:col-span-8' style={{ minHeight: '100px' }}>
          I AM CONTENT
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-4 xl:col-span-3'>
          <div className='flex flex-col'>
            <Button buttonType='tertiary' className='text-left mb-2' text='Pretend helpful link 1' />
            <Button buttonType='tertiary' className='text-left' text='Pretend helpful link 2' />
          </div>
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-12 border-t border-gray-25'>
          <WrapLoanSupportFooter>
            <LoanSupportFooter loan={loanFunded} />
          </WrapLoanSupportFooter>
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-12 py-4'>Footer</div>
      </div>

      <div className='my-16 border-b border-gray-25'>
        <h2 className='text-4xl'>Version 1.0</h2>
      </div>

      <div className='header-medium my-8'>General Support</div>
      <div className='w-full border grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
        <div className='flex bg-inactive-10 justify-center items-center col-span-4 md:col-span-8' style={{ minHeight: '100px' }}>
          I AM CONTENT
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-4 xl:col-span-3'>
          <GeneralSupport />
        </div>
      </div>

      {(content.retentionEmail || content.retentionPhone) && (
        <>
          <div className='header-medium my-8'>Retention Support</div>
          <div className='w-full border grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
            <div
              className='flex bg-inactive-10 justify-center items-center col-span-4 md:col-span-8'
              style={{ minHeight: '100px' }}
            >
              I AM CONTENT
            </div>
            <div className='col-span-4 md:col-span-8 lg:col-span-4 xl:col-span-3'>
              <RetentionSupport />
            </div>
          </div>
        </>
      )}

      <div className='header-medium my-8'>Team Support no MC</div>
      <div className='w-full border grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
        <div className='flex bg-inactive-10 justify-center items-center col-span-4 md:col-span-8' style={{ minHeight: '100px' }}>
          I AM CONTENT
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-4 xl:col-span-3'>
          <LoanSupport loan={loanInProcessNoMc} />
        </div>
      </div>

      <div className='header-medium my-8'>Team Support with MC</div>
      <div className='w-full border grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
        <div className='flex bg-inactive-10 justify-center items-center col-span-4 md:col-span-8' style={{ minHeight: '100px' }}>
          I AM CONTENT
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-4 xl:col-span-3'>
          <LoanSupport loan={loanInProcess} />
        </div>
      </div>

      <div className='header-medium my-8'>Home Loans Support with Servicing</div>
      <div className='w-full border grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
        <div className='flex bg-inactive-10 justify-center items-center col-span-4 md:col-span-8' style={{ minHeight: '100px' }}>
          I AM CONTENT
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-4 xl:col-span-3'>
          <LoanSupport loan={loanFunded} />
        </div>
      </div>

      <div className='header-medium my-8'>Home Loans Support with Servicing with Rate Alert</div>
      <div className='w-full border grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
        <div className='flex bg-inactive-10 justify-center items-center col-span-4 md:col-span-8' style={{ minHeight: '100px' }}>
          I AM CONTENT
        </div>
        <div className='col-span-4 md:col-span-8 lg:col-span-4 xl:col-span-3'>
          <LoanSupport loan={loanFunded} />
          <RateAlertPresentational loan={loanFunded} rateAlert={mockRateAlert} />
        </div>
      </div>
    </>
  );
};

export default SupportStyleguide;
