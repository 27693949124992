import cn from 'classnames';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import ConditionalWrapper from '../ui/conditionalWrapper/ConditionalWrapper';

export const MainLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='app-page'>
      <div className='flex flex-col flex-grow'>
        <Header />
        <main className='flex flex-col flex-grow'>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export const SingleColumnContent = ({ children }: { children: React.ReactNode }) => {
  return <div className='flex flex-col ml-auto mr-auto w-full lg:w-228'>{children}</div>;
};

export const LeftContentRightRail = ({
  classNames,
  ids,
  subnav,
  children,
  rightRail,
}: {
  classNames?: {
    'app-page-with-nav'?: string;
    'app-page-contents'?: string;
    'main-body'?: string;
  };
  ids?: {
    'right-rail-contents-wrapper'?: string;
  };
  subnav?: React.ReactNode;
  children: React.ReactNode;
  rightRail: React.ReactNode;
}) => {
  return (
    <ConditionalWrapper
      condition={!!subnav}
      wrapper={children => {
        return (
          <div className={cn('app-page-with-nav', classNames?.['app-page-with-nav'])}>
            {subnav}
            {children}
          </div>
        );
      }}
    >
      <div className={cn('app-page-contents', classNames?.['app-page-contents'])}>
        <div className={cn('main-body', classNames?.['main-body'], 'mb-12 lg:mb-0')}>
          <div className='main-body-contents'>{children}</div>
        </div>
        <div className='right-rail'>
          <div id={ids?.['right-rail-contents-wrapper']} className='right-rail-contents-wrapper'>
            <div className='right-rail-contents'>{rightRail}</div>
          </div>
        </div>
      </div>
    </ConditionalWrapper>
  );
};
